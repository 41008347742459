import React from 'react'
import { openMail } from '../constants/AppConstant'

function FairPlayPolicy() {
  return (
    <div className='px-[5%] py-[15%] md:py-[5%]'>

        <h6 className='font-[900] text-[16px] font_bold'>FairPlay Policy</h6>
        <h6 className='font_normal text-gray-500 text-[13px]'><span className='text-blue-500 hover:underline'>Home</span>  / <span>FairPlay Policy Guidelines</span></h6>

        <h6 className='my-4 font-[900] font_bold text-[14px]'>Last Update : April 12,2024</h6>

        <div>

            <h6 className='font-[800] text-[14px] font_bold'>Introduction</h6>
            <h6 className='text-[14px] font_normal mb-4'>At the Ludo Premier League Betting App, we are committed to providing a fair, transparent, and enjoyable gaming experience for all our users. This Fair Play Policy is designed to support this commitment by outlining the rules and behaviors expected of our users to ensure fair play and integrity in all games.</h6>

          
            <h6 className='font-[800] text-[14px] font_bold'>Commitment to Fairness</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'>We ensure that all game mechanics and algorithms provide equal chances of winning to all players without any biases.</li>
             <li className='text-[14px] font_normal mb-1.5'>We constantly monitor and audit game operations to uphold the highest standards of fairness and integrity.</li>
            </ul>
            <h6 className='font-[800] mb-2 text-[14px] font_bold'>Prohibited Practices</h6>
            <h6 className='text-[14px] font_normal'>Engaging in any of the following practices will be considered a violation of our Fair Play Policy:</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'>Cheating:</span> Any form of cheating, including the use of third-party software or tools that alter the game functionality, is strictly prohibited.</li>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'>Exploitation of Bugs:</span> Deliberately exploiting game bugs or glitches for personal gain is forbidden.</li>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'>Manipulation:</span> Attempting to manipulate game outcomes, including colluding with other players, is not allowed.</li>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'>Multiple Accounts:</span> Operating multiple accounts to gain an unfair advantage in games is prohibited.</li>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'>Account Sharing:</span> Sharing accounts or letting others access your account compromises the fairness of the game.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Detection and Monitoring</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> We employ advanced technologies and monitoring techniques to detect and prevent unfair practices.</li>
            <li className='text-[14px] font_normal mb-1.5'> Avoid sharing or promoting false information.</li>
           </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Consequences of Violation</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> Any violation of this Fair Play Policy may result in immediate temporary suspension or permanent ban of the involved accounts.</li>
            <li className='text-[14px] font_normal mb-1.5'> We reserve the right to revoke any in-app credits, rewards, or standings gained as a result of unfair practices.</li>
           </ul>
           <h6 className='font-[800] text-[14px] font_bold'>Reporting Unfair Practices</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> We encourage players to report any suspicious activities or violations of this Fair Play Policy through our in-app reporting tools.</li>
            <li className='text-[14px] font_normal mb-1.5'> All reports will be investigated thoroughly, and appropriate actions will be taken to maintain game integrity.</li>
           </ul>
           <h6 className='font-[800] text-[14px] font_bold'> Commitment to Continuous Improvement</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> We continuously strive to improve our detection methods and enhance our gaming environment to prevent abuse and ensure fair play.</li>
            <li className='text-[14px] font_normal mb-1.5'> Feedback from our community is invaluable and we welcome suggestions at <span onClick={openMail} className='text-blue-400 underline'>ludopremierleagues11@gmail.com</span>.</li>
           </ul>
          
           <h6 className='font-[800] text-[14px] font_bold'>Conclusion</h6>
            <h6 className='text-[14px] font_normal mb-4'> Our Fair Play Policy is integral to maintaining a safe and competitive environment where honesty and integrity are upheld. We thank all players for adhering to these principles and helping us create a fair and enjoyable gaming experience.</h6>
    
        
            <h6 className='text-[14px] font_normal mb-4'> This policy serves as a framework to guide conduct and ensure that all players have an equal opportunity to enjoy and succeed in the Ludo Premier League Betting App. Regular updates to this policy may be necessary to adapt to new technologies or strategies employed by players that may affect game fairness.</h6>

        </div>  
        
    </div>
  )
}

export default FairPlayPolicy
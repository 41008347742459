import React from 'react'
import { email } from '../constants/AppConstant'
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { BiMoneyWithdraw } from "react-icons/bi";

function About() {
  return (
    <div>
      
 
      <div className='relative bg-about_bg flex flex-col items-center justify-center min-w-screen overflow-hidden max-w-screen h-[200px] md:h-[250px] bg-center bg-cover'>
        <div className='bg-gradient-to-r  from-[#760c0a] via-[#430a08] to-[#380908] md:from-[#170212] md:via-[#2e0712] md:to-[#070112]  opacity-70 w-full h-[250px]'>
        <div className='px-[2%] mt-12 md:mt-0
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        flex flex-col items-center justify-center text-white py-[2%] z-20 md:py-[5%]'>
                <h1 className='text-[28px] font_thicksss mb-2'>About Us</h1>
                <h6 className=' font_thick text-[13px] opacity-80 mb-2 text-center md:w-[70%]'>Would like to know more about us  we are eager to explain who we are</h6>
                
              
            </div>
        </div>

      </div>
       
      <div className='w-screen px-[10%] py-[5%]'>
        <h6 data-aos="fade-left" className='font_bold'>About Us</h6>
        <h6  data-aos="fade-left"className='text-[14px] mb-2 font_thick break-word mt-2'>At LPL we offer you Ludo game with various leagues to stay entertained and earn at the same time. We give you an opportunity to use your Ludo knowledge and skill to convert your love for the game into cool cash awards. With our customer-centric approach, we ensure the highest possible gain for our players in most reliable and secure way.</h6>
        <h6 data-aos="fade-up" className='text-red-500 font_bold text-[15px]'>@ Ludo Premier Leauge</h6>
        
      <div >

        <div data-aos="fade-up" className='flex items-start mt-10'>
          <MdOutlineMailOutline size={40} className='bg-red-100 p-[8px] rounded-full' />
          <div className='ml-5'>
          <h6 className='font_thick '>For any Queries,write to</h6>
          <h6 className='font_bold -mt-[4px] text-blue-500 text-[14px] cursor-pointer'>{email}</h6>
          </div>
        </div> 
      </div>



      <div className='mt-10 bg-gray-100 py-10'>
      <h6 data-aos="fade-left" className='font_bold text-center'>Truly Best <span className='text-red-600 text-[20px]'>Gaming Platform</span> <br></br> </h6>
      <h6 data-aos="fade-right" className='font_normal mt-2 text-[12px] text-center'>Trusted by more than <span className='font_bold text-red-500'> 1 Million </span> people a handy ludo game to earn money!</h6>


      <div className='md:w-[50%] mx-auto md:flex mt-5 item-center justify-between'>

        <div data-aos="fade-up" className='md:w-[48%] bg-gradient-to-r mb-8 relative w-[90%] mx-auto  h-[100px] from-[#eeeeee] via-[#ffffff] to-[#eeeeee] border-2 shadow-lg border-gray-300 rounded-[12px] px-[14px] py-[10px] mb-2'>
           <div  className='flex items-center'>
              <AiOutlineSafetyCertificate className='text-red-500' />
              <h6 className='font_bold text-[13px] ml-2'>World class security</h6>
           </div>
           <ul className='ml-2 list-disc mt-1'>
            <li className='text-[12px] font_thick'>RNG certified gaming platform</li>
            <li className='text-[12px] font_thick'>100% safe and secure transaction</li>
            <li className='text-[12px] font_thick'>Complete data and identity privacy</li>
           </ul>

        </div>

        <div data-aos="fade-up" className='md:w-[48%] bg-gradient-to-r mb-8 relative w-[90%] mx-auto  h-[100px] from-[#eeeeee] via-[#ffffff] to-[#eeeeee] border-2 shadow-lg border-gray-300 rounded-[12px] px-[14px] py-[10px] mb-2'>
           <div className='flex items-center'>
              <BiMoneyWithdraw className='text-red-500' />
              <h6 className='font_bold text-[13px] ml-2'>Innovative gaming platform</h6>
           </div>
           <ul className='ml-2 list-disc mt-1'>
            <li className='text-[12px] font_thick'>Instant withdrawals & winnings.</li>
            <li className='text-[12px] font_thick'>Play multiple leagues.</li>
           </ul>

        </div>

      </div>


      </div>

      </div>

      



     
    </div>
  )
}

export default About
import React from 'react'

function ResponsibleGamePolicy() {
  return (
    <div className='px-[5%] py-[15%] md:py-[5%]'>

        <h6 className='font-[900] text-[16px] font_bold'>Responsible Game Policy</h6>
        <h6 className='font_normal text-gray-500 text-[13px]'><span className='text-blue-500 hover:underline'>Home</span>  / <span>Responsible Game Policy</span></h6>

        <h6 className='my-4 font-[900] font_bold text-[14px]'>Last Update : April 12,2024</h6>

        <div>

            <h6 className='font-[800] text-[14px] font_bold'>Introduction</h6>
            <h6 className='text-[14px] font_normal mb-4'>At Ludo Premier League Betting App, we are committed to providing a responsible gaming environment. It is our priority to ensure that our players enjoy a fun gaming experience while remaining fully aware of the social and financial harms associated with problem gambling. In accordance with industry standards and regulatory requirements, we provide various responsible gaming resources and tools to help our players manage their gaming activities responsibly.</h6>

            
            <h6 className='font-[800] text-[14px] font_bold'>Age Verification</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'>It is illegal for anyone under the age of 18 (or the legal age of majority in their jurisdiction) to open an account or to gamble on the Ludo Premier League Betting App. We perform age verification checks to ensure compliance with these age restrictions.</li>
             <li className='text-[14px] font_normal mb-1.5'> Accounts found to be held by underage individuals will be immediately closed and all funds forfeited.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Self-Exclusion and Time-Outs</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'> We provide the option for players to self-exclude or take a time-out from gaming as a way to prevent gambling addiction.</li>
             <li className='text-[14px] font_normal mb-1.5'> During the period of self-exclusion or time-out, we will not send the player any promotional materials and they will be blocked from logging in to their account.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>  Deposit Limits</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> Players have the option to set daily, weekly, or monthly deposit limits on their accounts. These limits help players control the amount of money they can deposit within a specific time period.</li>
            <li className='text-[14px] font_normal mb-1.5'> Once set, deposit limits can be decreased at any time but increasing the limit will require additional confirmation and a waiting period to ensure that the decision was made responsibly.</li>
           </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Reality Checks and Alerts</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> To help players keep track of the time spent playing, we offer reality check features that provide reminders of how long they have been active in a gaming session.</li>
            <li className='text-[14px] font_normal mb-1.5'> Players can set up these alerts from their account settings to receive notifications after certain intervals of play.</li>
           </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Access to Transaction History</h6>
            <h6 className='text-[14px] font_normal mb-4'> Players have access to their full transaction and gaming history to help them keep track of their spending and game play.</h6>
           
            <h6 className='font-[800] text-[14px] font_bold'>Training and Awareness</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> Our customer support team receives comprehensive training on problem gambling issues and are available to guide and support players affected by these issues.</li>
            <li className='text-[14px] font_normal mb-1.5'> We provide information and links to international and local problem gambling support organizations on our platform.</li>
            </ul>

            <h6 className='font-[800] text-[14px] font_bold'>Identifying Problem Gambling</h6>
            <h6 className='text-[14px] font_normal mb-4'>We monitor player behavior for signs of problem gambling and intervene where necessary to offer support or restrict gameplay according to our responsible gaming protocols.</h6>

            <h6 className='font-[800] text-[14px] font_bold'> Communication and Marketing</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> All promotions and marketing materials are designed to not exploit vulnerable players or promote excessive gambling.</li>
            <li className='text-[14px] font_normal mb-1.5'> Communication will always promote responsible gaming practices and include messages about the risks of gambling.</li>
            </ul>
        
        </div>  
        
    </div>
  )
}

export default ResponsibleGamePolicy
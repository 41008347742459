import React from 'react'
import { appLogo } from '../constants/AppConstant'
import {AiFillInstagram} from 'react-icons/ai'
import {IoLogoWhatsapp} from 'react-icons/io'
// import { mobile } from './constant/Appconstants'
import {MdCall} from 'react-icons/md';
import { FaTelegram } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';

function Footer() {

const navigator = useNavigate()  


const {pathname} = useLocation()  


const social_media = [
    // {'icon':FaFacebookF,name:'Facebook',id:1,path:'https://www.facebook.com/wudgres/'},
    {'icon':AiFillInstagram,name:'Instagram',id:2,path:'https://www.instagram.com/wudgres/?hl=en'},
    // {'icon':AiFillYoutube,name:'YouTube',id:4,path:'https://www.youtube.com/@wudgres'},
    {'icon':IoLogoWhatsapp,name:'WhatsApp',id:5,path:'https://api.whatsapp.com/send?phone=919844610001&text=Hi%20I%20would%20like%20to%20connect%20with%20you'},
    {'icon':FaTelegram,name:'Telegram',id:5,path:'tel://919844610001'},
    {'icon':MdCall,name:'Call Us',id:5,path:'tel://919844610001'},
    ]

    const menu = [
        {icon:'',name:'Home',path:'/'},
        {icon:'',name:'About',path:'/about_us'},
        {icon:'',name:'How To Play',path:'/how_to_play'},
        {icon:'',name:'Contact Us',path:'/contact_us'},
        {icon:'',name:'Download App',path:'/mobile_app'}
      ]

    const menu1 = [
        {id:1,icon:'',name:'Privacy Policy',path:'/privacy_policy'},
        {id:2,icon:'',name:'Terms & Condition',path:'/terms_condition'},
        {id:3,icon:'',name:'Community GuideLines',path:'/community_guidelines'},
        {id:4,icon:'',name:'Responsible Game Policy',path:'/responsible_game_policy'},
        {id:5,icon:'',name:'FairPlay Policy',path:'/fairplay_policy'},
    ]
    
  async function openpath(v){
    navigator(v)
  }  

  
  async function socialMedia(v){
    var a = document.createElement('a');
    a.href = v;
    a.target ='_blank'
    a.click();
  }

  return (
    <div className='bg-[#1e1e1e] w-screen'>

        <div className='grid md:grid-cols-4 gap-4 mx-5 md:mx-20 py-10'>
             <div>
                 <img src={appLogo} className='w-[150px] object-contain' />
                 <h6 className='text-[13px] font_bold  font-[700] text-white mt-2'>Ludo <span className='text-red-500'>Premier</span>  League</h6>
                 <h6 className='text-[13px] font_thick text-white opacity-80 mt-2'>189, 8th main, 1st cross road maruthi layout dasarahalli 560024</h6>
             </div>
             <div>
                <h6 className='capitalize font_bold font-[800] text-white py-[5px] text-[13px] mb-[5px]'>Quick Links</h6>
                 {menu?.map((m,i)=>(
                    <h6 onClick={()=>openpath(m?.path)} className={`text-white font_thick text-[12.5px] opacity-70 mb-[5px] cursor-pointer ${pathname === m?.path && 'text-red-500'} hover:opacity-100 hover:text-red-500`} key={i}>{m?.name}</h6>
                ))}
             </div>
             <div>
                 <h6 className='capitlaize font_bold font-[800] text-white py-[5px] text-[13px] mb-[5px]'>Other Links</h6>
                 {menu1?.map((m,i)=>(
                    <h6 onClick={()=>openpath(m?.path)} className={`text-white font_thick text-[12.5px] opacity-70 mb-[5px] cursor-pointer ${pathname === m?.path && 'text-red-500'} hover:opacity-100 hover:text-red-500`} key={i}>{m?.name}</h6>
                ))}
             </div>
             <div>
                 <h6 className='capitlaize font-[800] font_bold text-white py-[5px] text-[13px]'>Follow us</h6>
                 <h6 className='text-[12px] font_thick text-white  opacity-60 w-[70%]'>Use the below links to get latest update on the game  and other offers</h6>
                 <div className='flex items-center mt-2'>
                    {social_media?.map((m)=>
                        <div onClick={()=>socialMedia(m?.path)} key={m?.id} className='p-[4px] mr-[5px] hover:bg-red-600 rounded hover:scale-125 duration-300'>
                            <m.icon size={17} className='text-white opacity-80 hover:opacity-100 cursor-pointer' />
                        </div>
                    )}
                 </div>
             </div>
        </div>

        <h6 className='text-white pt-[10px] font_thick text-center text-[11px] opacity-80'>*This game may be habit-forming or financially risky. Play responsibly</h6>
        <h6 className='text-white py-[10px] font_thick text-center text-[11px] opacity-80'>*This game is applicable for users 18+ only.</h6>

        <div className='border-t-[1px] border-[#2e2e2e] py-[10px]'>
            <h6 className='text-center font_thick text-[8px] font-[500] opacity-70 text-white '>@2024-25 By <span className='text-red-500 font-[800]'>Ludo Premier League</span>  All rights Reserved Designed by Spinesoft.</h6>
            
        </div>
    </div>
  )
}

export default Footer
import React from 'react'
import { openMail } from '../constants/AppConstant'

function PrivacyPolicy() {
  return (
    <div className='px-[5%] py-[15%] md:py-[5%] w-screen overflow-x-hidden'>

        <h6 className='font-[900] text-[16px] font_bold'>Privacy Policy</h6>
        <h6 className='font_normal text-gray-500 text-[13px]'><span className='text-blue-500 hover:underline'>Home</span>  / <span>Privacy Policy</span></h6>

        <h6 className='my-4 font-[900] font_bold text-[14px]'>Last Update : April 12,2024</h6>
{/* 
        <div>

            <h6 className='font-[800] text-[14px] font_bold'>Introduction</h6>
            <h6 className='text-[14px] font_normal mb-4'>Welcome to the Ludo Premier League Betting App ("we", "us", "our"). We are committed to protecting your privacy and ensuring you have a positive experience on our app. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our app.</h6>

          
            <h6 className='font-[800] text-[14px] font_bold'>Information We Collect</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'>Personal Information:</span>This includes but is not limited to your name, email address, contact information, date of birth, and payment information, which we collect through the registration and transaction processes.</li>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'> Usage Data:</span> We automatically collect information on how the Service is accessed and used. This Usage Data may include details such as your device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our app that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.</li>
             <li className='text-[14px] font_normal mb-1.5'><span className='font_bold'> Location Data:</span> We may use and store information about your location if you give us permission to do so. We use this data to provide features of our service and to improve and customize our service.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>How We Use Your Information</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'>To provide and maintain our service.</li>
             <li className='text-[14px] font_normal mb-1.5'>To notify you about changes to our service.</li>
             <li className='text-[14px] font_normal mb-1.5'>To allow you to participate in interactive features of our service when you choose to do so.</li>
             <li className='text-[14px] font_normal mb-1.5'>To provide customer support.</li>
             <li className='text-[14px] font_normal mb-1.5'>To gather analysis or valuable information so that we can improve our service.</li>
             <li className='text-[14px] font_normal mb-1.5'>To monitor the usage of our service.</li>
             <li className='text-[14px] font_normal mb-1.5'>To detect, prevent, and address technical issues.</li>
             <li className='text-[14px] font_normal mb-1.5'>To process your transactions.</li>
             <li className='text-[14px] font_normal mb-1.5'>To manage your account and registration.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'> Sharing Your Information</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> With Service Providers: We may share your personal information with service providers to monitor and analyze the use of our service, to contact you.</li>
            <li className='text-[14px] font_normal mb-1.5'> For Business Transfers: We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            <li className='text-[14px] font_normal mb-1.5'> With Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.</li>
            <li className='text-[14px] font_normal mb-1.5'> With Business partners: We may share your information with our business partners to offer you certain products, services or promotions.</li>
            <li className='text-[14px] font_normal mb-1.5'> With other users: if you interact with other users or register through a social network or authentication service, your contacts on the service may see your name, profile, pictures and description of your activity.</li>
           </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Security of Your Data</h6>
            <h6 className='text-[14px] font_normal mb-4'>The security of your data is important to us but remember that no method of transmission over the Internet.</h6>
            
    
        
        </div>   */}


        <div>

        <h6 className='font_bold underline text-[14px]'>Privacy Policy of LUDO PREMIER LEAGUE</h6>

        <h6 className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE respects your privacy and recognizes the need to protect the personally identifiable information (any information by which you can be identified, such as name, address, and telephone number) you share with us. We would like to assure you that we follow appropriate standards when it comes to protecting your privacy on any of LUDO PREMIER LEAGUE’s Games, Applications, Website, its services and products vide this Policy and same should be read along with the Terms of Service (“ToS“) so provided on our Application and our on the Website. In such regard, This Privacy Policy along with ToS describes INSTANT TECH ORGANIZATION PRIVATE LIMITED its subsidiaries and affiliates, practices regarding, including your choices in relation to how we collect, store, use, share and secure your personal information across our application/website. It also describes your choices regarding use, access and correction of your Personal Information and your rights in relation to your personal information and how to contact us or supervisory authorities in the event you have a complaint.</h6>

        <h6 className='font_thick text-[13.5px] mt-2'>The terms “LUDO PREMIER LEAGUE” “we,” “us,” and “our” means INSTANT TECH ORGANIZATION PRIVATE LIMITED and our affiliates. We use the terms “you” and “your” to mean any person who is parent/guardian and their minor/ward, using our Platform, and any organization or person using the Platform on an organization’s behalf. For the purpose of this Privacy Policy, we use the word “Platform” to mean any website, mobile site(s) and mobile app(s), application, or service offered by LUDO PREMIER LEAGUE including content we offer and electronic communications we send. </h6>


        <h6 className='underline font_bold text-[14px] mt-5'>1. COLLECTION OF INFORMATION</h6>

        <ul className='ml-5 list-disc'>
          <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Information You Provide to Us:</span> We collect the information so provided by you directly to us. For example, we collect information you provide when you create an account, fill out a form, or communicate with us and same is considered as ‘Personal Information’ and ‘Associate Information’ in lines with Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. The said types of information we may collect may be private to vide way of accessing and/or registration on LUDO PREMIER LEAGUE’s Games, Applications, Website, its services and products, including but not limited to information about:</li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>Name, gender, age and contact details (i.e. email address and registered mobile number). Any person seeking to register to participate/play LUDO PREMIER LEAGUE has to be 18 years old or above.  Any parent of a minor under the belief or apprehension that your minor ward has provided LUDO PREMIER LEAGUE with Personal Information, is requested to please contact us and we will delete the information from our system. </li>
              <li className='font_thick text-[13.5px] mt-2'>Aadhar Card Details and PAN Card details in written and jpg form. </li>
              <li className='font_thick text-[13.5px] mt-2'>Provision of password and confirmation of the same as well as confirmation of your provided contact details and verification of Aadhar Card and PAN Card details (i.e. email address and registered mobile number) through such email and SMS verification. </li>
              <li className='font_thick text-[13.5px] mt-2'>User data, images, gifs, videos etc. and inquiry related information for availing registration of services, contests, offers and special events. </li>
              <li className='font_thick text-[13.5px] mt-2'>Such information as provided by You and data concerning usage of the Services and participation in playing LUDO PREMIER LEAGUE with its commercial partners for the purpose of facilitating user engagement, for marketing and promotional purposes and other related purposes. </li>
              <li className='font_thick text-[13.5px] mt-2'>Publicly and commercially available sources including from organizers of events. </li>
              <li className='font_thick text-[13.5px] mt-2'>Debit Card/Credit Card/Net Banking/Other Payment and other payment method data for Application/Website usage related purchases/services. If you use a feature on the Platform that requires payment of a entry fee options like will appear. In case of payments via credit or debit card, we will redirect you to registered third party payment gateways such as Razor pay and other such registered gateways. You may store and save your payment details like card numbers with the gateway. LUDO PREMIER LEAGUE will not have access to this data. All payment transactions are processed through secure payment gateway providers </li>
          </ul> 

          <li className='font_thick text-[13.5px] mt-2'>When you use our Platform, we automatically collect information about you, including:</li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Log Information:</span> We collect log information about your use of the Platform, including the type of browser you use, access times, pages viewed, your IP address, and the page you visited before visiting our Platform.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Device Information:</span> We collect information about the computer or mobile device you use to access our Platform, including the hardware model, operating system and version, unique device identifiers, and mobile network information. This may include Device identification (“ID”), which is a distinctive number associated with a desktop, laptop, smartphone or similar mobile or handheld device, but is different than a hardware serial number as well as brand and model, IP address, Device ID, MAC address, installed software, application usage data, hardware type, operating system information, browser information, unique identifiers in browser cookies, flash cookies, and HTML5 local storage, internet and on-line usage information.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Location Information:</span> We may collect information about the location of your device each time you use our Platform, or when you otherwise consent to the collection of this information. This information is only collected to provide and maintain the Service and the game experience as well as to help improve the gameplay for the user, as well as for advertising and analytics purposes.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Information Collected by Cookies and Other Tracking Technologies:</span> We and our service providers collect information using various technologies, p including cookies and pixel tags (which are also called clear GIFs, web beacons, or pixels). Cookies are small data files stored on your hard drive or in device memory that help us improve our Platform and your experience, and track usage of our Platform. Pixel tags are electronic images that may be used in our Platform or emails, and track usage of our Platform and effectiveness of communications. You can learn more about the types of cookies we and our service providers use by reading our Cookie Policy. </li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Other Information:</span> With your permission, we may collect other information from your device, such as contacts of individuals you wish to find or connect with, or calendar information you want to manage via the Platform. </li>
          </ul> 

          <li className='font_thick text-[13.5px] mt-2'>When you use our Platform, we automatically collect information about you, including:</li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Log Information:</span> We collect log information about your use of the Platform, including the type of browser you use, access times, pages viewed, your IP address, and the page you visited before visiting our Platform.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Device Information:</span> We collect information about the computer or mobile device you use to access our Platform, including the hardware model, operating system and version, unique device identifiers, and mobile network information. This may include Device identification (“ID”), which is a distinctive number associated with a desktop, laptop, smartphone or similar mobile or handheld device, but is different than a hardware serial number as well as brand and model, IP address, Device ID, MAC address, installed software, application usage data, hardware type, operating system information, browser information, unique identifiers in browser cookies, flash cookies, and HTML5 local storage, internet and on-line usage information.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Location Information:</span> We may collect information about the location of your device each time you use our Platform, or when you otherwise consent to the collection of this information. This information is only collected to provide and maintain the Service and the game experience as well as to help improve the gameplay for the user, as well as for advertising and analytics purposes.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Information Collected by Cookies and Other Tracking Technologies:</span> We and our service providers collect information using various technologies, p including cookies and pixel tags (which are also called clear GIFs, web beacons, or pixels). Cookies are small data files stored on your hard drive or in device memory that help us improve our Platform and your experience, and track usage of our Platform. Pixel tags are electronic images that may be used in our Platform or emails, and track usage of our Platform and effectiveness of communications. You can learn more about the types of cookies we and our service providers use by reading our Cookie Policy. </li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Other Information:</span> With your permission, we may collect other information from your device, such as contacts of individuals you wish to find or connect with, or calendar information you want to manage via the Platform. </li>
          </ul> 


          <li className='font_thick text-[13.5px] mt-2'><span>Information We Collect From Other Sources </span>We may collect information about you from other sources, such as through certain features on the Platform which you have elected to use, including:</li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Social Media Services:</span> If you register or log into your account through a third-party social media service, we will have access to some of your third-party account information from that service such as data received including but not limited to personal or non-personal information from your linked social media channels (which include but are not limited to name, email address, friend list, profile pictures or any other information that is permitted to be received, as authorized by that service terms and conditions. You have the ability to disable the connection between our Platform and your third-party account at any time.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Invitations:</span> If another member sends you an invitation through our Platform, we may receive your email address or phone number. If you are an invited guest, we will automatically send you an invitation to our Platform, and, if unanswered, a one-time follow-up invitation. LUDO PREMIER LEAGUE stores this contact information only to send these invitations and evaluate their success. You may opt to unsubscribe from future invitations using the instructions in those messages. You may also contact us at <span onClick={openMail} className='text-blue-400 underline'>ludopremierleagues11@gmail.com</span> to request that we remove this information from our database. </li>
          </ul> 

         <li>Any information provided by You to us should be complete and truthful to the best of your knowledge. LUDO PREMIER LEAGUE is not obliged to cross check or verify information provided by You and LUDO PREMIER LEAGUE will not take any responsibility for any outcome or consequence as a result of You providing incorrect information or concealing any relevant information from LUDO PREMIER LEAGUE.</li>


        </ul>

        <h6 className='underline font_bold text-[14px] mt-5'>2. USE OF INFORMATION</h6>
        <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Operating our Platform:</span> We may use information about you for various purposes related to operating our Platform, including to:</li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>Provide suggestions to improve our Platform, including to process transactions, and for development of new products and services; </li>
              <li className='font_thick text-[13.5px] mt-2'>Personalize the experience of the Platform; </li>
              <li className='font_thick text-[13.5px] mt-2'>Monitor and analyse trends, usage, and activities in connection with our Platform; </li>
              <li className='font_thick text-[13.5px] mt-2'>Preparation and Maintenance of LUDO PREMIER LEAGUES’s internal records and otherwise for management of our business and efficiency of performance of the Platform; </li>
              <li className='font_thick text-[13.5px] mt-2'>Detect and alert to fake, fraudulent or defamatory or immoral information, content, transactions, abuse, and other illegal activities, and protect the rights, safety of LUDO PREMIER LEAGUE, its members, their minor wards and others; </li>
          </ul> 

          <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Communicating with You:</span> We may use information about you for various purposes related to communicating with you, including to:</li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>Receive and respond to your comments, questions, and requests, and provide customer service; </li>
              <li className='font_thick text-[13.5px] mt-2'>Communicate with you about products, services, and events offered by LUDO PREMIER LEAGUE, affiliated and collaborating entities for events, offers and special events. </li>
              <li className='font_thick text-[13.5px] mt-2'>Deliver communications from other members, in accordance with the communication preferences that you indicate in your account; </li>
              <li className='font_thick text-[13.5px] mt-2'>Send you technical notices, updates, security alerts and support, and administrative messages; </li>
              <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE may alert you by email or phone (through sms/call/What’s App) to inform you about new service offerings, special price offers or trial offer, or purchase (or subsidize) products or services or other information which it perceives might be useful for you. </li>
          </ul> 

          <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Use Information Pertaining to Profile Creation:</span></li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>You may create a profile, containing information about you, which may include Personal Information, images, photographs, etc. for utility the services and other information under the Platform ("Profile"). </li>
              <li className='font_thick text-[13.5px] mt-2'>Certain information in your Profile may be made visible to certain third parties or to everyone, as the case may be, depending on the nature of the information and the privacy settings opted for by You when signing in and setting up your account on the Platform.  </li>
              <li className='font_thick text-[13.5px] mt-2'>Based on developments and requirements of LUDO PREMIER LEAGUE to enhance your experience, the information in your Profile may be designated and directed to be made accessible other than through the Platform, which may another app or an application programming interface (API), as the case may be or indexed for display by internet search providers targeting specific proper noun or common noun nomenclature searches concerning information on your Profile and control and frequency update of indexes of such third-party search engines update their indexes, is beyond scope of LUDO PREMIER LEAGUE; </li>
              <li className='font_thick text-[13.5px] mt-2'>In order to optimise and provide the highest quality services and facets of the Platform are so made available to you, it is agreed and understood that, in lines with parties as envisaged in Clause 2.2 of this Policy, that such parties may be permitted and able to review certain information in your Profile for provision of certain services by LUDO PREMIER LEAGUE on the Platform, which may be shared automatically. LUDO PREMIER LEAGUE does not share your contact information (email, full postal address or phone number) with those third parties unless you specifically request or agree to the sharing of the same. </li>
              <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE may alert you by email or phone (through sms/call/What’s App) to inform you about new service offerings, special price offers or trial offer, or purchase (or subsidize) products or services or other information which it perceives might be useful for you. </li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>LUDO PREMIER LEAGUE Public Forum:</span> The Services may provide you the opportunity to participate and post content publicly in forums, through interactive features and through other communication functionality ("LUDO PREMIER LEAGUE Public Forums") through utility of such Platform’s specific features to submit or post directed queries, constructive reviews, problems, suggestions, comments, testimonials, feedback, messages, images, videos, text or other material ("User Content"). Your User Content may also be posted in your Profile.  Note: User Content for review or for instruction from another user or designated party, as case may be, as part of service under the Platform, may be subject to public display associated to the Profile of the said user or designated party.</li>

          </ul>

          <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Advertising and Other Uses: LUDO PREMIER LEAGUE may use information about you for various other purposes, Private Including to:</span></li>
          <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>Provide advertisements, content, or features that match member profiles or interests; </li>
              <li className='font_thick text-[13.5px] mt-2'>Facilitate contests and other promotions.  </li>
              <li className='font_thick text-[13.5px] mt-2'>Combine with information we collect for the purposes described in this Privacy Policy; and </li>
              <li className='font_thick text-[13.5px] mt-2'>Carry out any other purposes described to you at the time the information was collected. </li>
          </ul>

          <h6 className='underline font_bold text-[14px] mt-5'>3. SHARING OF INFORMATION</h6>
          <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Through our Platform:</span> In lines with Clause 2.2 and 2.3 of this Policy, LUDO PREMIER LEAGUE will share some of the information we collect by displaying it on our Platform according to your member profile and account settings. Some information, such as user name, will be always public in nature. Some information, such as interests, is public by default but can be hidden on our Platform. Some information, such as group memberships, will always be visible to other members of that LUDO PREMIER LEAGUE group, and may be public, depending on the settings of that group. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>With Our Service Providers:</span> We may use service providers in connection with operating and improving the Platform to assist with certain functions, such as payment processing, email transmission, conducting surveys or contests, data hosting, managing our ads, and some aspects of our technical and customer support. If any of this information is shared through respective and designated Android Google Play Store or Apple iStore, then making of such purchases, any information so provided by You will be stored and secured by Google or Apple, as the case may be. LUDO PREMIER LEAGUE shall will only procure, retain and store details of concerned payment/order transaction and receipt data of such purchase on the respective operating system app store concerned in this regard. We take measures to ensure that these service providers access, process and store information about you only for the purposes we authorize, subject to confidentiality obligations. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Legally Mandated Disclosure:</span></li>
            <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>We may access, preserve, and disclose to third parties information about you if we believe disclosure is in accordance with, or required by, applicable law, regulation or legal process. We may also disclose information about you if we believe that your actions are inconsistent with our Terms of Service or related guidelines and policies, or if necessary to protect the rights, property or safety of, or prevent fraud or abuse of, LUDO PREMIER LEAGUE, its members or others. </li>
              <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE may share your information when we believe in good faith that such sharing is reasonably necessary in order to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. We will retain your personal data for as long as we deem it necessary to enable you to use our services, to provide our services to you (including maintaining the online user account (if created), to comply with applicable laws, resolve disputes with any parties and otherwise as necessary to allow us to conduct our business, including to detect and prevent fraud or other illegal activities. </li>
              <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE may also share your information to investigate and address threats or potential threats to the physical safety of any person, to investigate and address violations of this Privacy Policy or the Terms of Service, or to investigate and address violations of the rights of third parties and/or to protect the rights, property and safety of LUDO PREMIER LEAGUE, our employees, users, or the public. This may involve the sharing of your information with law enforcement, government agencies, courts, and/or other organizations on account of legal request such as court summons/notice/warrant, court order or government/statutory requirements to comply with the law. </li>
            </ul>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'> Social Sharing and Other Tools:</span>The Platform may offer social sharing features and other integrated tools which let you share and monitor actions you take on our Platform with third-party services, and vice versa enabling you to "like" a product within our Services or "share" content to other web sites. Where you opt to "like" or "share" content or to otherwise post information from or via the Platform to a third party web site, feature or application, that information may be publicly displayed, and the third party web site may have access to information about you and your use of the Platform. Such features let you share information with your fellow members or the public, depending on the settings you have chosen with the service. The service’s use of the information will be governed by their privacy policies, and we do not control their use of the shared data. These features may collect your IP address or other Device Identifier, which page you are visiting on our web site, and may set a cookie to enable the third party feature to function properly. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please review the privacy policies of the third party services providers of these features. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'> Affiliate Sharing and Merger, Sale, or Other Asset Transfers:</span>If LUDO PREMIER LEAGUE is involved in a merger, acquisition, financing, re-structuring, reorganization, bankruptcy, or sale of our assets, information about you may be shared, sold, or transferred as part of that transaction. We may also share information about you with current or future corporate parents, subsidiaries, or affiliates. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'> Third Party Specialists :</span> LUDO PREMIER LEAGUE may hire a third party for industry specific market research, monitoring site activity, processing of payments, maintaining our database, administering emails, administering contents and conduction of surveys and LUDO PREMIER LEAGUE will provide information to these third parties specifically for use in connection with these projects. The information (including aggregate cookie and tracking information) we provide to such third parties, alliance partners, or vendors are protected by confidentiality agreements and such information is to be used solely for completing the specific project, and in compliance with the applicable regulations. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'> Security of Personal Information:</span></li>
            <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE has incorporated and set up appropriate security measures to prevent your Personal Information from being accidentally lost or used or accessed unlawfully. Processing your Personal Data will be done only in an authorised manner and subject to a duty of confidentiality. We also have internal procedures to address any suspected data security breach. We will notify you a suspected data security breach where we are legally required to do so. </li>
              <li className='font_thick text-[13.5px] mt-2'>Notwithstanding anything contained anywhere in this Agreement; LUDO PREMIER LEAGUE cannot assure the absolute security to your Personal Information and by entering into or by logging into the Platform, you explicitly agree not to sue us for any data breach. </li>
            </ul>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Other Situations:</span>We may share de-identified or aggregated information about you. We may also share information about you with your consent or at your direction.</li>
          </ul> 

          <h6 className='underline font_bold text-[14px] mt-5'>4. OPTIONAL FACETS OF CHOICE</h6>
          <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Analytics and Advertising Services Provided by Others:</span> In lines with Clause 2.4 of this Policy, by exercising such choice regarding interest-based advertising, you may still receive advertisements, but the network from which you opted out will no longer deliver ads tailored to your interests. You should also know that logging out of your user account will not actually mean that you opt out of personalized advertisements. We may allow others to use cookies, web beacons, device identifiers, and other technologies to collect information about your use of the Platform and other websites and online services. See our Cookie Policy for details about these technologies and the information that they collect, use, or share, including how you may be able to control these services or opt out. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Your Choices: Account Information:</span> </li>
            <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE provides venue for you to review, change or delete personal information that we have collected from you by editing your account settings or by sending communication through <span onClick={openMail} className='text-blue-400 underline'>ludopremierleagues11@gmail.com</span> on the Platform. We shall not reject for your request for review, update, correct, and delete your personal information subject legal restrictions of territorial jurisdiction, unless: </li>
              <ul className='ml-5 list-disc'>
                  <li className='font_thick text-[13.5px] mt-2'>Means of communication of request is expressly abusive or unreasonably excessive; </li>
                  <li className='font_thick text-[13.5px] mt-2'>Where your request stands to infringe on the rights or safety of another person or persons; or </li>
                  <li className='font_thick text-[13.5px] mt-2'>If the information or material which is the subject matter of your pertains to any existing or anticipated legal proceedings between you and LUDO PREMIER LEAGUE; or  </li>
                  <li className='font_thick text-[13.5px] mt-2'>Where the execution of your request jeopardise negotiations between LUDO PREMIER LEAGUE or any investigation of possible unlawful activity.  </li>
              </ul>
              <li className='font_thick text-[13.5px] mt-2'>You have permanently delete or deactivate your account by editing your account settings or by sending communication through <span onClick={openMail} className='text-blue-400 underline'>ludopremierleague11@gmail.com</span> on the Platform. Such voluntary deletion of your LUDO PREMIER LEAGUE’ account will lead to loss possible deletion and loss of for public view. LUDO PREMIER LEAGUE may retain certain information as needed to comply with our legal obligations or for legitimate business purposes, such as to resolve disputes and enforce our legal rights. We may also retain cached or archived copies of information about you for a certain period of time. Thereafter, we will either delete your personal information or de-identify it so that it is anonymous and not attributed to your identity as envisaged in ‘Legally Mandated Disclosure’ of this Privacy Policy.  </li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Your Choices: Location Information:</span>When you first access the Platform, you may be asked to consent to our collection of location information. If you do not consent to this collection, you may not be able to use our mobile application. You can stop the collection of location information at any time from a particular device by changing the settings on that device, but note that our Platform or its features may no longer function properly if you do. </li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'> Your Choices: Cookies:</span>Most devices and web browsers are set to accept cookies by default. If you prefer, you may be able to remove or reject browser cookies. You can learn more about cookies, including how to block cookies Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Platform. </li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'> Your Choices: Promotional Communications:</span> You may opt out of receiving promotional messages from LUDO PREMIER LEAGUE by following the instructions in those messages or by adjusting the communication preferences that you indicate in your account. If you opt out, we may still send you non-promotional messages, such as those about your account or our ongoing business relations. Further Acceptance of a prize money/pay-outs by the You constitutes permission for LUDO PREMIER LEAGUE, and its affiliates to use the Your name, likeness, voice and comments for advertising and promotional purposes in any media worldwide for purposes of advertising and trade without any further permissions or consents and / or additional compensation whatsoever. You further undertake that the said information will be available for promotional purposes as planned and desired by LUDO PREMIER LEAGUE without any charge. The exact dates remain the sole discretion of LUDO PREMIER LEAGUE. Promotional activities may include but not be limited to press events, internal meetings and ceremonies/functions. </li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Geocentric Privacy Specifications:</span></li>
              <ul className='ml-5 list-disc'>
                  <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE is based in India and information so collected shall be governed by laws of Republic of India. By accessing or using the Platform or otherwise providing information to us, you consent to the processing, transfer, and storage of information within Republic of India and other countries, where you may not have the same rights and protections as you do under local law </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>California Privacy Rights:</span> If you are a California resident, you have the right to request information from us about how we may share certain categories of your information with third parties, for the third parties' direct marketing purposes. To exercise this right, please contact us at <span onClick={openMail} className='text-blue-400 underline'>ludopremierleagues11@gmail.com</span> with "California Privacy Rights" in the subject line to receive such information. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>European Union Data and Privacy Rights:</span> Where required by European law, LUDO PREMIER LEAGUE shall only transfer personal data to recipients offering an adequate level of data protection. In these situations, as may be required, LUDO PREMIER LEAGUE make contractual arrangements to ensure that your personal data is still protected in line with European standards. You can ask us to see a copy of these contractual agreements using the contact <span onClick={openMail} className='text-blue-400 underline'>ludopremierleagues11@gmail.com</span>  with "European Union Data and Privacy Rights" in the subject line to receive such information. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>China Privacy Rights:</span> LUDO PREMIER LEAGUE will maintain the confidentiality of your information, but may provide or transfer your information to third parties as detailed above under "How do We Use Information" and “Disclosure of Information Shared” which may include transferring your information to persons to whom LUDO PREMIER LEAGUE are required to make disclosures to under applicable law within or outside China. LUDO PREMIER LEAGUE will generally maintain your information on servers in India, or any other data centres outside the People’s Republic of China. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Security:</span> We employ measures designed to protect information about you, both during transmission and once we receive it. For example, we use firewalls designed to protect against intruders and test for network vulnerabilities. However, no method of transmission over the internet or method of electronic storage is completely secure. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Policy Scope:</span> This Privacy Policy does not apply to information you provide to third parties, such as members of LUDO PREMIER LEAGUE, and others with whom you may share information about you. Our Platform may direct you to a third-party service or a portion of our Platform controlled by a third party (typically through the use of a frame or pop-up window separate from other content on our Platform). Disclosure of information to these third parties is subject to the relevant third party’s privacy policy. LUDO PREMIER LEAGUE shall bear no responsibility whatsoever for the third-party privacy policy or content, even if we link to those services from our Platform. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Consent:</span> By submitting data to us or our agent or using the Site, you consent to our use of your data in the manner set out in this Privacy Policy. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Conditions of Use:</span> LUDO PREMIER LEAGUE does not warrant that this Platform, website, application or API, its servers, or email sent by us or on our behalf are virus free. LUDO PREMIER LEAGUE will not be liable for any damages of any kind arising from the use of this website, including, but not limited to compensatory, direct, indirect, incidental, punitive, special or consequential damages, loss of data, goodwill, business opportunity, income or profit, loss of or damage to property and claims of third parties. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Governing law and Jurisdiction:</span> In the event of any dispute arising between the parties with respect to this Privacy Policy, the same shall be referred to the Sole Arbitrator and the arbitration shall be in accordance with Arbitration and Conciliation Act of 1996 (or such subsequent Act or amendment as per laws of India). The language of arbitration proceeding shall be English. The seat and place of arbitration shall be Bangalore and the decision of the Arbitrator shall be final and binding on both parties herein. This Policy and terms and conditions therein shall be subject to the exclusive jurisdiction of courts in Bengaluru, Karnataka, India and shall be governed by the Indian laws. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Revisions to this Policy:</span> We may amend this Privacy Policy from time to time. When we do, we will provide notice to you by publishing the most current version and revising the date at the top of this page. If we make any material change to this policy, we will provide additional notice to you, such as by sending you an email or displaying a prominent notice on our Platform. By continuing to use the Platform after any changes come into effect, the revised Privacy Policy shall by effect apply to you. </li>
                  <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Contact Us:</span> If you have any questions about this Privacy Policy, please contact us at <span onClick={openMail} className='text-blue-400 underline'>ludopremierleagues11@gmail.com</span> . </li>
              </ul>
            </ul>
          </ul>  


        </div>
        
    </div>
  )
}

export default PrivacyPolicy
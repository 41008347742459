import React from 'react'
import { openMail } from '../constants/AppConstant'

function TermsCondition() {
  return (
    <div className='px-[5%] py-[15%] md:py-[5%] w-screen overflow-x-hidden'>

        <h6 className='font-[900] text-[16px] font_bold'>Terms Of Use</h6>
        <h6 className='font_normal text-gray-500 text-[13px]'><span className='text-blue-500 hover:underline'>Home</span>  / <span>Terms & Condition</span></h6>

        <h6 className='my-4 font-[900] font_bold text-[14px]'>Last Update : April 12,2024</h6>

        {/* <div>

            <h6 className='font-[800] text-[14px] font_bold'>Introduction</h6>
            <h6 className='text-[14px] font_normal mb-4'>Welcome to the Ludo Premier League Betting App. By accessing our app and using our services, you agree to be bound by the following terms and conditions. Please read them carefully.</h6>

            <h6 className='font-[800] text-[14px] font_bold'>Eligibility</h6>
            <h6 className='text-[14px] font_normal mb-4'>To use our app, you must be at least 18 years old or of legal age to participate in betting activities according to the laws of your jurisdiction. It is your responsibility to ensure that your participation in betting activities is legal in your country, state, or province.</h6>

            <h6 className='font-[800] text-[14px] font_bold'>Account Registration</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'>Users must register for an account by providing accurate, up-to-date, and complete information.</li>
             <li className='text-[14px] font_normal mb-1.5'> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
             <li className='text-[14px] font_normal mb-1.5'> You agree to immediately notify us of any unauthorized use of your account or any other security breaches.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>User Conduct</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'> Users must not use the app for any fraudulent or illegal activity.</li>
             <li className='text-[14px] font_normal mb-1.5'> Any form of cheating, hacking, or exploitation of the app’s features is strictly prohibited.</li>
             <li className='text-[14px] font_normal mb-1.5'> Betting must be done responsibly and within the limits of your financial capabilities.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'> Deposits and Withdrawals</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> All financial transactions must be made through approved payment methods.</li>
            <li className='text-[14px] font_normal mb-1.5'>  Users must comply with our deposit and withdrawal procedures, including any minimum amounts and verification processes.</li>
            <li className='text-[14px] font_normal mb-1.5'> The app may impose transaction limits and fees, which will be communicated through the app interface.</li>
           </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Privacy Policy</h6>
            <h6 className='text-[14px] font_normal mb-4'>Your privacy is important to us. Please read our Privacy Policy to understand how we collect, use, and share information about you.</h6>
            <h6 className='font-[800] text-[14px] font_bold'>Termination and Suspension</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> We reserve the right to suspend or terminate your account if you violate any of the terms and conditions stated herein.</li>
            <li className='text-[14px] font_normal mb-1.5'> Users can deactivate their accounts at any time through their account settings.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Disclaimers and Limitation of Liability</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> The app is provided on an "as-is" and "as-available" basis without any warranties of any kind.</li>
            <li className='text-[14px] font_normal mb-1.5'> We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of the app.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Dispute Resolution</h6>
            <h6 className='text-[14px] font_normal mb-4'>Any disputes related to these terms and conditions will be resolved through arbitration, according to the rules of the jurisdiction set forth at the time of the dispute.</h6>
            <h6 className='font-[800] text-[14px] font_bold'>Changes to Terms and Conditions</h6>
            <h6 className='text-[14px] font_normal mb-4'>We reserve the right to modify these terms at any time. Your continued use of the app following any such modification constitutes your acceptance of the new terms.</h6>
            <h6 className='font-[800] text-[14px] font_bold'>Contact Us</h6>
            <h6 className='text-[14px] font_normal mb-4'>If you have any questions about these terms, please contact us at [<span className='text-blue-400 underline'>ludopremierleagues11@gmail.com</span>].</h6>

    
        
        </div>   */}


        <div>
           <h6 className='font_bold underline text-[14px]'>Terms of Service of PREMIER LEAGUE</h6>

           <h6 className='font_thick text-[13.5px] mt-2'>This ‘Terms of Service’ has some essential information about the terms and policies you need to read agree and follow before using LUDO PREMIER LEAGUE’s Platform. The terms and policies are described in this ‘Terms of Service’ that includes related policies and guidelines discussed below. LUDO PREMIER LEAGUE may amend these terms and policies whenever it is found so necessary to maintain and improve our relationship and service requirements. This ‘Terms of Service’ is an “agreement between You and LUDO PREMIER LEAGUE” and not between you and any other member/user of LUDO PREMIER LEAGUE Platform. However, the instant ‘Terms of Service’ may contain parcel and parcel certain guidelines, rules and benefits that are applicable and binding upon You towards other member/users of LUDO PREMIER LEAGUE. For the purpose of this Privacy Policy, we use the word “Platform”. </h6>

           <h6 className='font_thick text-[13.5px] mt-2'>Your use/access/browsing of the Platform or the Services or products therein or registration (with or without payment/with or without Participation) on the Platform through any means shall signify Your acceptance of this Terms of Service Terms and Your agreement to be legally bound by the same. LUDO PREMIER LEAGUE has the right to amend these Terms of Service from time to time without notice and without liability to you or any third party</h6>


           <h6 className='underline font_bold text-[14px] mt-5'>1. AGREEMENT</h6>

           <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>The Agreement:</span> LUDO PREMIER LEAGUE enables you and members to experience and utilise technological avenues and solutions to play and participate in playing Ludo game tournaments for offers and rewards scheme. The terms “LUDO PREMIER LEAGUE” “we,” “us,” and “our” means LUDO PREMIER LEAGUE Private ltd. and our affiliates. We use the terms “you” and “your” to mean any person using our Platform, and any organization or person using the Platform on an organization’s behalf. We use the word “Platform” to mean any website, mobile site(s) and mobile app(s), application, or service offered by LUDO PREMIER LEAGUE including content we offer and electronic communications we send. We provide our Platform to you subject to these Terms of Service. We use “Terms of Service” and “Agreement” interchangeably to mean this document together with our Usage and Content Policies, Group Policies, Organizer and Leadership Standards, Member Restrictions, Payment Policies, and Trademark Usage Guidelines. Your use of the Platform signifies that you have read and agreed to this Agreement. If you are using the Platform for an organization, you agree to this Agreement on behalf of that organization, and represent you have authority to bind that organization to the terms contained in this Agreement. If you do not or are unable to agree to this Agreement, do not use our Platform.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Revisions to this Agreement:</span> We may amend or modify this Agreement from time to time. When we do, we will notify by publish the most current version with its revised date. If we make any material change to this Agreement, we will provide additional notice to you, such as by sending you an email or displaying an important notice on our Platform or any other method as may updated from time to time. By continuing to use the Platform after any changes come into effect, you agree to the revised Agreement.</li>
           </ul>

           <h6 className='underline font_bold text-[14px] mt-5'>2. YOUR ACCOUNT AND MEMBERSHIP </h6>

            <ul className='ml-5 list-disc'>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Eligibility:</span> The minimum requirement for a person registration for membership and utility of the services on the Platform, meeting the pre-requites required by such person to form legally binding contracts as per the Indian Contract Act, 1872 and LUDO PREMIER LEAGUE will accept your representation such minimum pre-requisites while sign up formalities based on principle of assumption of truthful representation. Persons who are minors, un-discharged insolvents etc. are not eligible to register for Our products or Services. As a minor if You wish to use Our products or Services, such use shall be made available to You by Your legal guardian or parents, who has agreed to these Terms. Additional eligibility requirements for some particular portions of our Platform may be laid down by any member who has created or been vested with the capacity to moderate or administer that section of our Platform. Note: In the event a You indicate, while entering an address, that You are a resident of either Andhra Pradesh, Assam, Karnataka, Nagaland, Odisha, Sikkim or Telangana, then  You will not be permitted/eligible to proceed to sign up for any facet of the paid version of the LUDO PREMIER LEAGUE.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Account Registration:</span> Please read the LUDO PREMIER LEAGUE’s Privacy Policy (‘Privacy Policy’) with respect to registration with LUDO PREMIER LEAGUE, the use of the Platform and services and products therein before using the same. In the event of any discrepancy between the Terms of Service and any other policies with respect to the Platform, the provisions of the Terms of Service shall prevail. Your single Account Registration will be subject to terms and conditions of verification of veracity and authentication of user data/personal data by LUDO PREMIER LEAGUE through such process as by be conveyed by LUDO PREMIER LEAGUE from time to time as the case may be.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Suspension of Your Account:</span> We in our sole discretion, may modify, suspend or terminate your account or right to use the Platform if we establish that you have dishonoured this Agreement, including any of the policies or guidelines that are part of this Agreement, that are in the best interest of the LUDO PREMIER LEAGUE community, or to protect our brand or Platform. We also may do away with accounts of members who are inactive for an extended period of time subject to issuance a one-time notification. A member who has the ability to moderate or administer a particular portion of our Platform in his or her sole discretion also be vested with the ability to amend, suspend, or terminate your access to that portion of the Platform.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Termination of Account:</span> LUDO PREMIER LEAGUE will reserve the right to terminate your registered Membership on the Platform and / or refuse to provide You with access to the products and/or services on the Platform, if it is detected that You do not meet the criteria as per Clause 2.1 of Terms of Service and the consent to use the products or Services is not made by You or any information provided by You is inaccurate. LUDO PREMIER LEAGUE shall bear no responsible for any consequence that arises as a result of misuse of any kind of the Platform or the services rendered therein that may occur by virtue of any person including a minor registering for/on the same.</li>
              <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Account Information and Security:</span> During the registration process, you will be required to provide us with some basic general information amongst which will include a registered and active email address and a password therein as well as Aadhar Card and PAN Card details input. Keep your email address and other account information up to date and precise. LUDO PREMIER LEAGUE shall bear no liability or responsibility if any e-mail/SMS remains unread by YOU as a result of such e-mail getting delivered to the Your junk or spam folder. Also, you agree to maintain the security and confidentiality of your password, failure of which may lead to deactivation/disablement of your LUDO PREMIER LEAGUE’s account. LUDO PREMIER LEAGUE shall bear no responsibility whatsoever for the sharing your registered LUDO PREMIER LEAGUE account credentials with other members shall be borne by you and LUDO PREMIER LEAGUE and any of the consequences for such failure on your part to maintain the security and confidentiality. You agree to exit/log-out of Your respective account at the end of each session and LUDO PREMIER LEAGUE shall not be responsible for any loss or damage that may result if You fail to comply with the same. In the event that it comes your notice of unauthorised usage of your LUDO PREMIER LEAGUE’s account information and password, you will be required to inform us at <span onClick={openMail} className='text-blue-400 underline'>ludopremierleague11@gmail.com</span> within 24 hours of notification of such unauthorised usage.</li>
            </ul>


            <h6 className='underline font_bold text-[14px] mt-5'>3. SERVICE & PRODUCT FEES, PAYMENTS, AND OFFERS</h6>

            <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Entry/Participation Fees:</span> On perusal of display service and products offered by LUDO PREMIER LEAGUE on the Platform and their respective Entry/Participation Fees (’E&PF’), You seek to convey interest in the said displayed/offered service or products on the Platform, designated means on the Platform. Subject to successful registration on the Platform and confirmation of opted service and/or products by way of the Platform (‘Participation’), LUDO PREMIER LEAGUE charge the E&PF for confirmed service and/or product on the Platform vide payment method of credit card, debit card or net banking (‘Payment method’), as the case may be, prior to time and date of commencement of rendering to You of service and/or product by LUDO PREMIER LEAGUE on its Platform (‘Service Commencement Time’). On receipt of the E&PF, LUDO PREMIER LEAGUE will through the Platform linked your respective membership Account, will generate a system invoice for selected and confirmed service and/or product on the Platform along with subsequent concurrent invoice from LUDO PREMIER LEAGUE which shall be inclusive of all applicable Taxes. Unless otherwise stated, all E&PF and all transactions on the Platform are in INDIAN RUPEES (INR). </li>
            <li className='font_thick text-[13.5px] mt-2'>Subject to these Terms of Services and other relevant terms and conditions therewith, all amounts collected from You are held in a separate non-interest earning bank account/s. The said account/s are operated by a third party appointed by LUDO PREMIER LEAGUE in accordance with such terms and conditions as conveyed by LUDO PREMIER LEAGUE. From these bank accounts, the said pay-outs can be made to (a) You (towards your respective withdrawals), (b) LUDO PREMIER LEAGUE (towards its Platform Fees) and to (c) Government (towards TDS on Winnings Amount). LUDO PREMIER LEAGUE receives only its share of the platform Fees through the said Escrow Agent. </li>
            <li className='font_thick text-[13.5px] mt-2'>You may participate in LUDO PREMIER LEAGUE wherein the You have to contribute a pre-specified contribution towards the Prize Money Pool of such contest in LUDO PREMIER LEAGUE, which will be passed on to the winner(s) of the concerned contest after the completion of the contest of LUDO PREMIER LEAGUE as per the terms and conditions of such Contest. It is clarified that LUDO PREMIER LEAGUE shall have no right or interest in this Prize Money Pool, and shall act only as as an intermediary engaged in collection and distribution of the concerned Prize Money Pool in accordance with the terms and conditions as provided by LUDO ARENA. The amount to be paid-in by You towards the Prize Money Pool would also be debited from the You’s account balance maintained with such designed entity that hold the same in trust.</li>
            <li className='font_thick text-[13.5px] mt-2'>All prizes moneys/pay-outs shall be subject to deduction of tax (“TDS”) as per the Income Tax Act 1961. The TDS rate prescribed by the Government of India with respect to any prize money/pay-out amount that is in excess of Rs. 10,000/will be 30%. The TDS of 30% shall be deducted on Net Winnings ( prize money amount - contest entry amount - cash bonus). In case of any revisions by the Government of India to the aforementioned rate in the future, TDS will be deducted by LUDO PREMIER LEAGUE in accordance with the then current prescribed TDS rate. You will be provided for your winnings, a TDS certificates in respect of such tax deductions. You shall be responsible for payment of any other applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the prize money/pay-outs. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Queries:</span> In case of any assistance or queries concerning the Participation or Participation Session period or E&PF or Payment Method or anything less, you make reach out and contact us at contact us at support @ <span onClick={openMail} className='text-blue-400 underline'>ludopremierleague11@gmail.com</span>.</li>
            </ul>

            <h6 className='underline font_bold text-[14px] mt-5'>4. YOUR CONTENT AND PRIVACY</h6>

            <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Your Content :</span> You are solely responsible for your Content. “Content” means any information, material, posted to our Platform or otherwise provide to us such as feedback, discussion, queries, doubts, comments, or suggestions shared with us. You agree that you and your Content shall not violate the rights of any third party such as copyrights, trademarks, contract rights, privacy rights, or publicity rights, this Agreement. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Content License from You :</span> We do not claim ownership of your Content. However, in order to enable us to operate, improve, promote, and protect LUDO PREMIER LEAGUE and our Platform, and to ensure we do not violate any rights you may have in your Content, you hereby grant LUDO PREMIER LEAGUE a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub licensable, transferable right and license ( including a waiver of any moral rights) to use, host, store, reproduce, amend, publish, publicly display, publicly perform, distribute, and create derivative works of, your Content and to commercialize and exploit the copyright, trademark, publicity, and database rights you have in your Content. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Privacy :</span> LUDO PREMIER LEAGUE collects registration and other information about you through our Platform. Please refer to our Privacy Policy and Cookie Policy for details on how we collect, use, and disclose this information. These policies do not govern use of information that you provide to third parties, such as other members of LUDO PREMIER LEAGUE’ Platform. </li>
            </ul>

            <h6 className='underline font_bold text-[14px] mt-5'>5. YOUR USE OF OUR PLATFORM</h6>

            <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Our Policies, Guidelines and Applicable Laws :</span> When you use our Platform, we require that you follow these Terms of Service, Usage and Content Policies, Privacy Policies, Rule and Regulations and Trademark Usage Guidelines. You also agree to comply with all applicable laws, rules and regulations, and to not violate or infringe the rights of any third party. If you do not comply, we may amend, suspend or terminate your account or access to the Platform, at our sole discretion.  </li>
            <li className='font_thick text-[13.5px] mt-2'>You shall not use Platform for any commercial purposes of any kind or in any way that is unlawful, or harms the Company or any other person or entity as determined by the Company</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Content of Others :</span> LUDO PREMIER LEAGUE does not control the Content of other members. When we become aware of inappropriate Content on our Platform, we reserve the right to investigate and take appropriate action, but we do not have any obligation to monitor, nor do we take responsibility for, the Content of other members. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Interactions with Others :</span> LUDO PREMIER LEAGUE is not a party to any offline arrangements made through our Platform. LUDO PREMIER LEAGUE does not conduct or require background checks on members, and does not attempt to verify the truth or accuracy of statements made by members. LUDO PREMIER LEAGUE makes no representations or warranties concerning the conduct or Content of any members or their interactions with you. Further deceptive emails, websites, blogs etc. claiming to be from or associated with LUDO PREMIER LEAGUE may or are circulating on the internet. These emails, websites, blogs etc. often include our logo, photos, links, content or other information. Some emails, websites, blogs etc. call the user requesting the user to provide login name, password etc. or inform You that the You have allegedly and falsely won a prize/ gift or provide a method to commit illegal/ unauthorized act or deed or request provision of detailed personal information or a payment of any kind and same is in no way associated with LUDO PREMIER LEAGUE. For Your own protection, LUDO PREMIER LEAGUE strongly recommend not responding to such emails or using these websites, blogs etc. LUDO PREMIER LEAGUE may use the information provided by you to LUDO PREMIER LEAGUE, including your email address or phone number, to contact you about the Services availed by You or to inform you of our updated Services if any.  </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>No Resale, No Modification :</span> Our Platform contains proprietary and confidential information and is protected by intellectual property laws. Unless LUDO PREMIER LEAGUE expressly permits it through this Agreement, you agree not to amend, reproduce, sell or charge a fee, offer to sell or charge a fee, make, create derivative works based on, or distribute, decompile, reverse engineer, or disassemble the contents of the Application and / or our Website and/or Services/ products (i.e. the Platform) or alter, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Platform, or remove any copyright, trademark registration, or other proprietary notices from the contents of our Platform,  including any data, or Content of others. If you are a developer working with our API, you should review our API license in Section 5.8, 5.9 and 5.10 of the Terms of Service for more information on how this impacts you. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>No Technical Interference with the Platform :</span> You agree that you will not engage in any activity or post any information or material that interferes with or disrupts, or that is designed to interfere with or disrupt, the Platform or any hardware used in connection with the Platform or disrupt or interfere with the security of, or otherwise cause harm to, the Platform, materials, systems resources, or gain unauthorized access to Membership Accounts, passwords, servers or networks connected to or accessible through the Platform or any affiliated or linked sites. You will not interfere with any other person or member’s use or enjoyment of the Platform or other affiliated sites, or engage in disruptive attacks such as denial of service attack on the Platform; </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Platform Modifications :</span> LUDO PREMIER LEAGUE works hard to continuously improve our Platform. As such LUDO PREMIER LEAGUE has the right to change modify, suspend, or discontinue and/or eliminate any aspect(s), features or functionality of the Platform as it deems fit at any time without notice. You expressly agree that LUDO PREMIER LEAGUE makes no commitment, express or implied, to maintain or continue any aspect of the Platform. You agree that the Company shall not be liable to You or any third party for any modification, suspension or discontinuance of the Platform. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Third Party Sites and Services :</span> he Platform contains links to third party sites, and is integrated with various third party services, applications and sites that may make available to you their content and products. We do not represent or control these third parties and are not responsible for those sites or services or their content or products. These third parties may have their own terms and policies, and your use of them will be governed by those terms and policies. </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>API License :</span> LUDO PREMIER LEAGUE offers an application programming interface, also known as an “API”. Subject to your compliance with this Agreement and the policies and guidelines that are part of this Agreement, including our Trademark Usage Guidelines, LUDO PREMIER LEAGUE grants to you a limited, non-exclusive, non-transferable, non-sub licensable, revocable license to use the LUDO PREMIER LEAGUE application programming interface, including data or other Content made available via the LUDO PREMIER LEAGUE API, (“LUDO PREMIER LEAGUE API”) solely to facilitate the development of communication, interactions, networking, event and group related applications using Platform data and developer tools. We refer to this license in this Agreement as the “API License”. For any application you develop with the LUDO PREMIER LEAGUE API, you agree to do the following: </li>
            <ul className='ml-5 list-disc'>
               <li className='font_thick text-[13.5px] mt-2'>Provide and comply with a publicly accessible privacy policy that describes method utilised for collection, sharing, use, and retention of user information. Your privacy policy must be held to the same standards of protection of personal information of LUDO PREMIER LEAGUE’ members as that of LUDO PREMIER LEAGUE’ Privacy Policy. </li>
               <li className='font_thick text-[13.5px] mt-2'>Don’t cache or store data you receive via the LUDO PREMIER LEAGUE API for an unreasonable amount of time given the relevance of that data and how it is used on your application. </li>
               <li className='font_thick text-[13.5px] mt-2'>Protect any keys used to access the LUDO PREMIER LEAGUE API and any data you receive via the LUDO PREMIER LEAGUE API against unauthorized access, use or disclosure, and immediately report security issues to <span onClick={openMail} className='text-blue-400 underline'>ludopremierleague11@gmail.com</span>. </li>
               <li className='font_thick text-[13.5px] mt-2'>Clearly indicate in the application that the application uses the LUDO PREMIER LEAGUE API but is not verified by LUDO PREMIER LEAGUE. </li>
               <li className='font_thick text-[13.5px] mt-2'>Only use the most current version of the LUDO PREMIER LEAGUE API. </li>
               <li className='font_thick text-[13.5px] mt-2'>Only use the LUDO PREMIER LEAGUE API in accordance with any documentation or other written instructions provided by LUDO PREMIER LEAGUE, and do not attempt to circumvent any limitations or restrictions. </li>
               <li className='font_thick text-[13.5px] mt-2'>Do not introduce any harmful, infringing or unlawful data or material via the LUDO PREMIER LEAGUE API, including viruses or other malware. </li>
               <li className='font_thick text-[13.5px] mt-2'>Don’t make unreasonable uses of the LUDO PREMIER LEAGUE API such as uses that substantially replicate our Platform or significant aspects of the Platform, or that mislead, confuse, or surprise members. </li>
            </ul>
            <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE reserves all rights not expressly granted under this Agreement. By availing the utilisation of LUDO PREMIER LRAGUE API, You hereby authorize us to monitor your use of the LUDO PREMIER LEAGUE API in order to verify your compliance with the terms of this Agreement, and to operate, improve and protect the Platform. For more documentation on usage our API, or to request an API key, see our API page</li>
            <li className='font_thick text-[13.5px] mt-2'>You may terminate this API License by discontinuing use of LUDO PREMIER LEAGUE’ API. We may terminate the LUDO PREMIER LEAGUE API at any time for any reason. This API License terminates automatically if (a) you violate any term of this Agreement, (b) we provide a written notice of termination, or (c) we discontinue your access to the LUDO PREMIER LEAGUE API. All sections restricting your access to and use of any data you receive via the LUDO PREMIER LEAGUE API will survive any termination of these API License terms.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Prohibited Activities:</span> No Member on the Platform shall be act or perform any of the following prohibited activities while accessing and utilising the Platform:</li>
            <ul className='ml-5 list-disc'>
               <li className='font_thick text-[13.5px] mt-2'>Any post, upload, publishment, submission or transmission any Content that content (i) is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another’s privacy; (ii) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual, ethnicity or group or otherwise objectionable; (iii) infringes, misappropriates or violates a third party's patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (iv) is fraudulent, false, misleading (directly or by omission or failure to update information) or deceptive; </li>
               <li className='font_thick text-[13.5px] mt-2'>Use of any program, algorithm or methodology, deep-links, page-scrape, robot, spider or other automatic device or any similar or equivalent manual process, to increase traffic to the Platform; </li>
               <li className='font_thick text-[13.5px] mt-2'>Formation of teams for the purpose of collusion between You and any other user(s) for participating in contests/activities/games organized on the Platform by LUDO PREMIER LEAGUE or any other form of cheating is strictly prohibited. </li>
               <li className='font_thick text-[13.5px] mt-2'>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity </li>
               <li className='font_thick text-[13.5px] mt-2'>Stalking, intimidating and/or harassing another and/or inciting other to commit violence or is violent or threatening or promotes violence or actions that are threatening to any other person; </li>
               <li className='font_thick text-[13.5px] mt-2'>Transmitting material that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;   </li>
               <li className='font_thick text-[13.5px] mt-2'>promotes illegal or harmful activities or substances; </li>
               <li className='font_thick text-[13.5px] mt-2'>Post, transmission or make available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files flood pings, denial-of-service attacks, backdoors, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology or programs that may harm the Application/services, interests or rights of other Members on the Platform or limit access or use the Platform in any manner that could damage, disable, overburden or impair any of the Platform’s servers or the networks connected to any of the servers on which the Platform is hosted. </li>
               <li className='font_thick text-[13.5px] mt-2'>Sans specific and express consent to use or control the same, the display, publish, provide, put forth, share or make available any content or material concerning which You have no rights under law or concerning which You have contractual or fiduciary relationship  </li>
               <li className='font_thick text-[13.5px] mt-2'>Post, transmission or make available any material that affects the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent; </li>
               <li className='font_thick text-[13.5px] mt-2'>Advertising or selling on the Platform whether the same is for profit or not, any Application or any products, services or domain names or otherwise  </li>
               <li className='font_thick text-[13.5px] mt-2'>Use any programme, device or algorithm to gain access, acquire, reproduce, replicate observe or monitor the Platform and any content therein through any surreptitiously obtain the same through the Platform. </li>
               <li className='font_thick text-[13.5px] mt-2'>Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws related to the access to or use of the Platform violate any requirements, procedures, policies or regulations of networks connected to the Platform, or engage in any activity prohibited by these Terms of Service; </li>
               <li className='font_thick text-[13.5px] mt-2'>Soliciting Members or others including, without limitation, solicitations for contributions, political funding, religious funding or donations or use any public forum for commercial purposes and inducements of any kind or otherwise solicit any Member or recruit by employee of LUDO PREMIER LEAGUE, as the case may be, to join a competitor of LUDO PREMIER LEAGUE; </li>
               <li className='font_thick text-[13.5px] mt-2'>Use of the Platform for purposes that are (i) that violates or is not permitted by these Terms of Service; and (ii) any applicable law, statutory guidelines, legislations, regulation or generally accepted business practices or guidelines in the relevant jurisdiction; </li>
            </ul>
            <li className='font_thick text-[13.5px] mt-2'>You further acknowledge that You are solely responsible for any consequence resulting from you participating in LUDO PREMIER LEAGUE or being associated with LUDO PREMIER LEAGUE or around any activity associated with LUDO PREMIER LEAGUE. You understand that LUDO PREMIER LEAGUE shall not assume any liability or responsibility for any financial loss that You may incur or sustain as a result of participation in any contest/activity associated with LUDO PREMIER LEAGUE.  </li>

            </ul>

           <h6 className='underline font_bold text-[14px] mt-5'>6. RELEASE</h6>
           <h6 className='font_thick text-[13.5px] mt-2'>You agree to release us and our officers, directors, shareholders, agents, employees, consultants, affiliates, subsidiaries, sponsors, and other third-party partners (referred to in this Agreement as “LUDO PREMIER LEAGUE Parties”) from claims, demands, and damages (direct and consequential) of every kind and nature, known and unknown, now and in the future (referred to in this Agreement as “Claims”), arising out of or in any way connected with any transaction with a third party, your interactions with other members, or in connection with any group or any event, within or outside the Platform.   </h6>

           <h6 className='underline font_bold text-[14px] mt-5'>7. INDEMNIFICATION</h6>
           <h6 className='font_thick text-[13.5px] mt-2'>You agree to indemnify, defend and hold all LUDO PREMIER LEAGUE, its officers, directors, employees and agents, harmless from any  claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, made due to or arising in any way out of (a) your violations of these Terms of Service, (b) your use, misuse, or abuse of our Platform, (c) your Content, (d) your violation of any law, statute, ordinance or regulation or the rights of a third party, or (e) your participation or interaction with any Member or a group or an event that violates these Terms of Service. You agree to promptly notify us of any third party Claims, cooperate with LUDO PREMIER LEAGUE in defending such claims and pay all fees, costs and expenses associated with defending such Claims [including but not limited to costs any injuries, losses, expenses or damages (compensatory, direct, incidental, consequential or otherwise)]. You agree not to settle any Claim without our prior written consent. </h6>

           <h6 className='underline font_bold text-[14px] mt-5'>8. WARRANTY DISCLAIMER AND LIMITATION OF LIABILITY</h6>
           <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>WARRANTY DISCLAIMER :</span> OUR PLATFORM IS PROVIDED TO YOU “AS IS” AND ON AN “AS AVAILABLE” BASIS. WE DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, INCLUDING BUT NOT LIMITED TO STATUTORY WARRANTIES, AND THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE ALSO DISCLAIM ANY WARRANTIES REGARDING (A) THE RELIABILITY, TIMELINESS, ACCURACY, AND PERFORMANCE OF OUR PLATFORM, (B) ANY INFORMATION, ADVICE, SERVICES, OR GOODS OBTAINED THROUGH OR ADVERTISED ON OUR PLATFORM OR BY US, AS WELL AS FOR ANY INFORMATION OR ADVICE RECEIVED THROUGH ANY LINKS TO OTHER WEBSITES OR RESOURCES PROVIDED THROUGH OUR PLATFORM, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE PLATFORM, AND (D) THE CORRECTION OF ANY ERRORS IN THE PLATFORM, (E) ANY MATERIAL OR DATA OBTAINED THROUGH THE USE OF OUR PLATFORM, AND (F) DEALINGS WITH OR AS THE RESULT OF THE PRESENCE OF MARKETING PARTNERS OR OTHER THIRD PARTIES ON OR LOCATED THROUGH OUR PLATFORM. YOUR USE OF ANY INFORMATION OR MATERIALS ON THIS WEBSITE/APPLICATION/SERVICES/PRODUCTS (i.e. THE PLATFORM) IS ENTIRELY AT YOUR OWN RISK, FOR WHICH WE SHALL NOT BE LIABLE. IT SHALL BE YOUR OWN RESPONSIBILITY TO ENSURE THAT SERVICES PROVIDED BY US MEET YOUR SPECIFIC REQUIREMENTS.  </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>LIMITATION OF LIABILITY :</span> YOU AGREE THAT IN NO EVENT SHALL ANY LUDO ARENA PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES,  INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF ANY LUDO ARENA PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) ARISING OUT OF OR IN CONNECTION WITH (A) OUR PLATFORM OR THIS AGREEMENT OR THE INABILITY TO USE OUR PLATFORM (HOWEVER ARISING, INCLUDING OUR NEGLIGENCE), (B) STATEMENTS OR CONDUCT OF OR TRANSACTIONS WITH ANY MEMBER OR THIRD PARTY ON THE PLATFORM, (C) YOUR USE OF OUR PLATFORM OR TRANSPORTATION TO OR FROM LUDO ARENA EVENTS, ATTENDANCE AT LUDO ARENA EVENTS, PARTICIPATION IN OR EXCLUSION FROM LUDO ARENA GROUPS OR LUDO ARENA EVENTS AND THE ACTIONS OF YOU OR OTHERS AT LUDO ARENA EVENTS, OR (D) ANY OTHER MATTER RELATING TO THE PLATFORM. OUR LIABILITY TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE GREATER OF RS.100 OR THE AMOUNT OF FEES, IF ANY; YOU PAID TO US WITHIN 12 MONTHS PRIOR TO THE ACTION THAT MAY GIVE RISE TO LIABILITY. THE LIMITATIONS SET FORTH ABOVE IN THIS SECTION 8 WILL NOT LIMIT OR EXCLUDE LIABILITY FOR OUR GROSS NEGLIGENCE, FRAUD, OR INTENTIONAL, MALICIOUS, OR RECKLESS MISCONDUCT.  </li>
           </ul> 

           <h6 className='underline font_bold text-[14px] mt-5'>9. GOVERNING LAW AND DISPUTE RESOLUTION</h6>

           <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'>The validity of this Agreement, the construction and enforcement of its terms, and the interpretation of the rights and duties of the Parties shall be governed by the laws of India.</li>
            <li className='font_thick text-[13.5px] mt-2'>In the event of any dispute arising between the parties with respect to these Terms of Service, the same shall be referred to the Sole Arbitrator and the arbitration shall be in accordance with Arbitration and Conciliation Act of 1996 (or such subsequent Act or amendment as per laws of India). The language of arbitration proceeding shall be English. The seat and place of arbitration shall be Bengaluru, Karnataka and the decision of the Arbitrator shall be final and binding on both parties herein. This Policy and terms and conditions therein shall be subject to the exclusive jurisdiction of courts in Bengaluru, Karnataka, India and shall be governed by the Indian laws.</li>
           </ul> 

           <h6 className='underline font_bold text-[14px] mt-5'>10. INTELLECTUAL PROPERTY</h6>
           <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>LUDO PREMIER LEAGUE’ Intellectual Property :</span> LUDO PREMIER LEAGUE Intellectual Property” means all of LUDO PREMIER LEAGUE’ intellectual property and intellectual property rights worldwide including, but not limited to, inventions, patents, copyright (including renewal rights), trademarks, trade names, logos, service marks, corporate names, trade secrets, know-how, mask works, text, images, confidential information, computer software (including source code), processes, discoveries, methods, graphics, video, script and audio, contained in the Application, Website, Services & Products, domain names and all other forms of intellectual property, whether registered or not, and any applications for registration thereof anywhere in the world, in the name of LUDO PREMIER LEAGUE; </li>
            <li className='font_thick text-[13.5px] mt-2'>No LUDO PREMIER LEAGUE Intellectual Proprietary Information may be copied, downloaded, reproduced, modified, republished, uploaded, posted, transmitted or distributed in any way without obtaining prior written permission from LUDO PREMIER LEAGUE and nothing on the Platform shall be or deemed to confer a license of or any other right, interest or title to or in any of LUDO PREMIER LEAGUE Intellectual Property rights, to You.  </li>
            <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE’ Trademark Usage Guidelines explain how you may and may not use them. Our Platform, including our material on the Platform, is also our or our licensors’ intellectual property. Except as described in the Trademark Usage Guidelines and as otherwise permitted by law, you agree not to use LUDO PREMIER LEAGUE’ intellectual property without our prior written consent </li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Intellectual Property of Others: </span> </li>
            <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'>LUDO PREMIER LEAGUE respects the intellectual property of others, and we expect our members to do the same. We may, in appropriate circumstances and in our discretion, remove or disable access to material that infringes on the intellectual property rights of others. We may also restrict or terminate access to our Platform to those who we believe to be repeat infringers. </li>
            <li className='font_thick text-[13.5px] mt-2'>Subject to reading of Clause 10.5.1. of this Terms of Service, You agree and acknowledge that certain contents on the Platform may belong to third parties and the said content is reproduced by LUDO PREMIER LEAGUE reproduced with prior consent from said party and all rights relating to such content will remain with such third party. Further, you recognize and acknowledge that the ownership of all trademarks, copyright, logos, service marks and other intellectual property owned by any third party shall continue to vest with such party and You are not permitted to use the same without the consent of the respective third party.</li>
           </ul> 
           </ul> 

           <h6 className='underline font_bold text-[14px] mt-5'>11. TERM</h6>
           <h6 className='font_thick text-[13.5px] mt-2'>This Terms of Service shall be effective for till the time You have access or utilise the Platform or continue playing/participating on the Platform or until such time You or LUDO PREMIER LEAGUE, as per Clause 12 of these Terms of Service, terminate the same.</h6>

           <h6 className='underline font_bold text-[14px] mt-5'>12. TERMINATION FOR CONVENIENCE</h6>
           <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'>You may terminate this Agreement at any time via the "Cancel Account" feature on the Site or by sending us an email. If you cancel your LUDO PREMIER LEAGUE Account, any and all Participation/s will be automatically cancelled and any refund will depend upon the terms of the applicable cancellation policy. Without limiting our rights specified below, LUDO PREMIER LEAGUE may terminate this Agreement for convenience at any time by giving you 30 days' notice via email to your registered email address.</li>
            <li className='font_thick text-[13.5px] mt-2'>If You violate or cause material breach of the Terms of Service, the same may result in immediate suspension or termination of Your Membership Account or access to our Platform and same shall preclude LUDO PREMIER LEAGUE from its reserved rights to pursue any legal remedy available. In such instances, the Company may also disclose Your Membership Account Information if required by any Governmental or legal authority. You understand that the violation of these Terms of Service could also result in civil or criminal liability under applicable laws. </li>
            <li className='font_thick text-[13.5px] mt-2'>In case non-material breach of Terms of Service, LUDO PREMIER LEAGUE shall issue you a notice and opportunity to rectify/resolve the said breach. </li>
           </ul> 

           <h6 className='underline font_bold text-[14px] mt-5'>13. FORCE MAJEURE</h6>
           <h6 className='font_thick text-[13.5px] mt-2'>In the event that (i) LUDO PREMIER LEAGUE is unable to observe or perform the terms of these Terms of Service; (ii) access to the Platform is blocked; (iii) The Platform are suspended or made unavailable by reason of causes beyond its control including (but not limited to) equipment, system or transmission link malfunction or failure, fire, lockdown, pandemic, flood, explosion, acts of elements, acts of God, accidents, epidemics, strikes, evacuation, notified disasters, lockouts, power blackouts or failure, labour disputes, legislative requirement, administrative order, court order or by any other causes which are cannot be or beyond the control or reasonably be expected to avoided, the performance of LUDO PREMIER LEAGUE‘s obligations as affected by such causes shall be excused for the duration of the such events. LUDO PREMIER LEAGUE shall not be liable for any delay, loss, damage or inconvenience whatsoever caused or arising from or in connection with any one or more of the above-mentioned events. In the event that such events cause LUDO PREMIER LEAGUE to terminate the offering of Participation, LUDO PREMIER LEAGUE shall not be liable for any damage, costs or expenses, and shall not be required to refund any fees paid.</h6>


           <h6 className='underline font_bold text-[14px] mt-5'>14. MISCELLANEOUS</h6>
           <ul className='ml-5 list-disc'>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Notices : </span>Except as otherwise stated in this Agreement or as expressly required by law. Any notice to you shall be given to the most current email address in your account.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Entire Agreement : </span>These terms of Service, including the Usage and Content Policies, Privacy Policies, Restrictions, Payment Policies, and Trademark Usage Guidelines, constitutes the entire agreement between you and LUDO PREMIER LEAGUE, superseding any prior agreements between you and LUDO PREMIER LEAGUE on such subject matter.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>No Agency : </span>No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship between you and LUDO PREMIER LEAGUE is intended or created by this Agreement. A member of the LUDO PREMIER LEAGUE Platform is not LUDO PREMIER LEAGUE’ representative or agent, and may not enter into an agreement on LUDO PREMIER LEAGUE’ behalf.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>As is : </span>You may face service disruptions, including, but not limited to disconnection or communication interferences due to issues in the internet infrastructure used for providing or accessing the Services or due to issues with the hardware and software used by you. You understand that LUDO PREMIER LEAGUE has no control over these factors and LUDO PREMIER LEAGUE shall not be responsible for any interruption in Services and You take full responsibility for any risk of loss due to Service interruptions for any such reason.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Assignment : </span>This Agreement is not assignable, transferable or sub licensable by you except with LUDO PREMIER LEAGUE’s prior written consent, but may be assigned or transferred by us to any affiliate or subsidiary, or in connection with a merger, acquisition, corporate reorganization, sale of all or substantially all of LUDO PREMIER LEAGUE’ assets, or similar transaction.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>No Waiver : </span>A Party’s failure to exercise or enforce any right or provision of these Terms of Services shall not constitute a waiver of such right or provision and does not waive any right to act with respect to subsequent or similar breaches.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Severance : </span>If any provision of this Agreement is found to be invalid by a court of competent jurisdiction, you and LUDO PREMIER LEAGUE nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and that the other provisions of this Agreement will remain in full force and effect.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Survival : </span>If You or we terminate this Terms of Service, the clauses of these Terms of Service that reasonably should survive termination of the Agreement will remain in effect.</li>
            <li className='font_thick text-[13.5px] mt-2'><span className='font-800 underline font_bold'>Violations : </span>Please report any violations of this Terms of Service by a You or Member to <span onClick={openMail} className='text-blue-400 underline'>ludopremierleague11@gmail.com</span>.</li>
           </ul> 

        </div>
        
    </div>
  )
}

export default TermsCondition
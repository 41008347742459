import logo from '../images/lpl.png'
import mobileapplogo from '../images/ludogame.png'
import gameplaylogo from '../images/play.png'
import mobileapplogo1 from '../images/app1.png'


import h1 from '../images/how_to_play/1.png'
import h2 from '../images/how_to_play/2.png'
import h3 from '../images/how_to_play/3.png'
import h4 from '../images/how_to_play/4.png'


import r1 from '../images/reviews/1.jpeg'
import r2 from '../images/reviews/2.jpeg'
import r3 from '../images/reviews/3.jpeg'
import r4 from '../images/reviews/4.jpeg'


export const appLogo = logo
export const mobileappLogo = mobileapplogo
export const gamePlayLogo = gameplaylogo
export const mobileAppLogo1 = mobileapplogo1

export const htp1 = h1
export const htp2 = h2
export const htp3 = h3
export const htp4 = h4


export const rev1 = r1
export const rev2 = r2
export const rev3 = r3
export const rev4 = r4

export const address = '# 189, 8th main, 1st cross road maruthi layout dasarahalli 560024'
export const email = 'ludopremierleagues11@gmail.com'
export const phone = '+91 7599002457'


export function openMail(){
    var a = document.createElement('a');
    a.href = `mailto:${email}`;
    a.target ='_blank'
    a.click();

}

export function openCall(){
    var a = document.createElement('a');
    a.href = `tel:${phone}`;
    a.target ='_blank'
    a.click();

}

export function openUrl(v){
    var a = document.createElement('a');
    let urlAndroid = ''
    let urlIos = ''
    a.href =  v == 1 ? urlAndroid : urlIos;
    a.target ='_blank'
    a.click();

}
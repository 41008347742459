import React, { useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import {BiError} from 'react-icons/bi'
import { address, email, phone } from '../constants/AppConstant'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


function Contact() {

  const navigator = useNavigate()
  const [data,setdata] =  useState({name:'',mobile:'',email:'',message:''})
  const [error,seterror] =  useState({name:'',mobile:'',email:'',message:''})

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  
  function submit(){
    if(!data.name){
      seterror({...error,name:'The name field is required*'})
    }else if(!data.mobile || data.mobile.length !== 10){
      seterror({...error,mobile:'Enter a valid 10 digit mobile no*'})
    }else if(!data.email){
      seterror({...error,email:'The email field is required*'})
    }else if(!data.message){
      seterror({...error,message:'The message field is required*'})
    }else{
      axios.post('https://wudnberg.com/api/admin/auth/service_query/',data)
      .then((res)=>{
        toast.success(res?.data?.data)
        setdata({name:'',mobile:'',email:'',message:''})
        seterror({name:'',mobile:'',email:'',message:''})
      }).catch((err)=>{
      })
    }
  }

  return (
    <div >
       <div className='relative flex items-center justify-center bg-contact_us_bg h-[200px] md:h-[250px] bg-cover bg-bottom  px-4 py-[10px] bg-gradient-to-r  from-[#760c0a] via-[#430a08] to-[#380908] md:from-[#170212] md:via-[#2e0712] md:to-[#070112]'>

       <div className='bg-gradient-to-r absolute  from-[#760c0a] via-[#430a08] to-[#380908] md:from-[#170212] md:via-[#2e0712] md:to-[#070112]  opacity-70 w-full h-full'>
        </div>   
          

           <div className='px-[2%] flex flex-col items-center justify-center text-white py-[2%] z-20 md:py-[5%]'>
                <h1 className='text-[28px] font_thicksss mb-2'>Loved To Meet You</h1>
                <h6 className=' font_thick text-[13px] opacity-80 mb-2 text-center md:w-[70%]'>We have a young team who will love to help you , with the query releated to app and other services, Just give as a call or meet us directly</h6>
                
           </div>
        </div>


          <h6 data-aos="fade-left" className='text-center font_expand text-[20px] mt-16'>Contact Us</h6>
          <h6 data-aos="fade-right" className='font_thick text-[13px] text-center mt-2 w-[80%] mx-auto'>Use the below form so that our support team can get back to you as soon as possible to reslove your query</h6>


          <div className='relative w-full bg-map bg-no-repeat bg-contain bg-right-top px-10 sm:px-0 mx-auto md:w-8/12 py-10 mt-0'>
      <Toaster/>
        <div className='md:flex items-center md:items-start justify-between my-10 md:my-20'>
            <div  data-aos="fade-up" className='md:mr-5 md:w-[60%]'>
                <h6 className='text-[14px] mb-2 text-left font_thick'><span className='font-black font_bold'> Address :</span> {address}</h6>  
               
                <h6 className='text-[14px] mb-2 font_thick'><span className='font-black font_bold'> Phone :</span> {phone}</h6>  
                <h6 className='text-[14px] mb-2 font_thick'><span className='font-black font_bold'> Email :</span> {email}</h6>  

                <h6 className='text-[12px] font_bold font-[900]'>Name</h6>
                <input value={data?.name} name="name" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.name && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.name}</h6>}

                <h6 className='text-[12px] font_bold font-[900] mt-1'>Mobile</h6>
                <input value={data?.mobile} name="mobile" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.mobile && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.mobile}</h6>}

                <h6 className='text-[12px] font_bold font-[900] mt-1'>Email</h6>
                <input value={data?.email} type='email' name="email" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.email && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}

                <h6 className='text-[12px] font_bold font-[900] mt-1'>Message</h6>
                <textarea value={data?.message} name="message" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.message && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.message}</h6>}

                <h6 className='transition w-28 text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#000] pt-1 px-3 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[13px] flex items-center' onClick={submit}>CONTACT US</h6>

            </div>
            <div data-aos-delay="500" data-aos="fade-down" className='ml-0 mt-10 md:mt-0 md:ml-5'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6814857740687!2d77.60516667349377!3d13.055934954436516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae177fb07baadb%3A0x2da6fefc1e8fd364!2s1st%20Cross%20Rd%2C%20Maruthi%20Nagar%2C%20Maruthi%20Layout%2C%20Dasarahalli%2C%20Bengaluru%2C%20Byatarayanapura%20CMC%20and%20OG%20Part%2C%20Karnataka%20560024!5e0!3m2!1sen!2sin!4v1713611322482!5m2!1sen!2sin" className='w-full md:w-[600px]' height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0" style={{border:0}}></iframe>
            </div>
        </div>

    </div>


           <div  data-aos="fade-up" data-aos-delay="300">
           </div>
    </div>
  )
}

export default Contact
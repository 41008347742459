import { useEffect, useState } from "react";
import "./App.css"
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import CommunityGuideLines from "./pages/CommunityGuideLines";
import DownloadApp from "./pages/DownloadApp";
import FairPlayPolicy from "./pages/FairPlayPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResponsibleGamePolicy from "./pages/ResponsibleGamePolicy";
import TermsCondition from "./pages/TermsCondition";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import HowToPlay from "./pages/HowToPlay";
import About from "./pages/About";
import Splash from "./pages/Splash";
import PointSystem from "./pages/PointSystem";


function App() {

  const [loader,setloader] = useState(false)

  useEffect(()=>{
    setloader(true)
    AOS.init();
    setTimeout(() => {
    setloader(false)
    }, 2500);
  },[])

  const router = createBrowserRouter([
    {
      path: "",
      element:
      <>
        <Navbar />
        <Home />
        <Footer />
      </>
    }, 
    {
      path: "/",
      element:
      <>
      <Navbar />
      <Home />
      <Footer />
    </>
    }, 
   
    {
      path: "/contact_us",
      element: 
      <>
      <Navbar />
      <Contact />
      <Footer />
      </> 
    }, 
    {
      path: "/about_us",
      element: 
      <>
      <Navbar />
      <About />
      <Footer />
      </> 
    }, 
    {
      path: "/how_to_play",
      element: 
      <>
      <Navbar />
      <HowToPlay />
      <Footer />
      </> 
    }, 
    {
      path: "/mobile_app",
      element: 
      <>
      <Navbar />
      <DownloadApp />
      <Footer />
      </> 
    }, 
    {
      path: "/how_to_play",
      element: 
      <>
      <Navbar />
      <HowToPlay />
      <Footer />
      </> 
    },   
    {
      path: "/point_system",
      element: 
      <>
      <Navbar />
      <PointSystem />
      <Footer />
      </> 
    },    
    {
      path: "/privacy_policy",
      element: 
      <>
      <Navbar />
      <PrivacyPolicy />
      <Footer />
      </> 
    },  
    {
      path: "/terms_condition",
      element: 
      <>
      <Navbar />
      <TermsCondition />
      <Footer />
      </> 
    },  
    {
      path: "/community_guidelines",
      element: 
      <>
      <Navbar />
      <CommunityGuideLines />
      <Footer />
      </> 
    },  
    {
      path: "/responsible_game_policy",
      element: 
      <>
      <Navbar />
      <ResponsibleGamePolicy />
      <Footer />
      </> 
    }, 
    {
      path: "/fairplay_policy",
      element: 
      <>
      <Navbar />
      <FairPlayPolicy />
      <Footer />
      </> 
    },  
    
    {
      path: "*",
      element: 
      <>
      <Navbar />
      <NotFound />
      <Footer />
      </> 
    },  
  ])

  return (
    <div className="h-[100%] w-screen overflow-x-hidden">
     {loader ? 
     <Splash />
     :
     <RouterProvider router={router} />}
    </div>
  );
}

export default App;

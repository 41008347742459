import React,{useState} from 'react'
import { MdKeyboardArrowDown,MdKeyboardArrowUp } from "react-icons/md";
import { IoMdStar,IoMdStarHalf } from "react-icons/io";
import { FaApple } from 'react-icons/fa';
import { IoLogoAndroid } from 'react-icons/io';
import { htp1, htp2, htp3, htp4, rev1, rev2, rev3, rev4, mobileAppLogo1, openUrl } from '../constants/AppConstant';
import { PiGameController,PiMedalLight } from "react-icons/pi";
import { LuUserPlus2 } from "react-icons/lu";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { BiMoneyWithdraw } from "react-icons/bi";


import banner1 from '../images/banner/Banner_large.jpg'
import banner_mobile1 from '../images/banner/Banner.jpg'

function Home() {

  const [selectedFaq,setselectedFaq] = useState('')
  const [index,setindex] = useState(0)
  const images = [banner1]

  const images_mobile = [banner_mobile1]

  const faq = [
    {
      id:1,
      label:'What games can I play on LPL',
      value:"You can play Ludo game on LPL which includes lot of leagues and leagues are playable with real money with competitive real players."
    },
    {
      id:2,
      label:'Do we really win money on LPL',
      value:"Yes, you will have to play contests against real world opponents and beat them to win."
    },
    {
      id:3,
      label:'Is it leagal to play Fantasy ludo game',
      value:"I will share you the screenshot."
    },
    {
      id:4,
      label:'How to add money on LPL',
      value:"Click on the wallet symbol displayed on top of the page and then click on ‘Add Funds’, after which you’ll be guided through the entire payment process."
    },
    {
      id:5,
      label:'How longdoes it take to withdraw money on LPL',
      value:"Once your KYC is done, it only takes a few second to withdraw money from LPL."
    },
    {
      id:6,
      label:'How much can I win on LPL',
      value:"Players can win up to crores on LPL by playing multiple contests on LPL."
    }
  ]
   
  const reviews = [
    {
      name:'Shivam',
      img:rev1,
      game:'Ludo Event Winner',
      date:'2024-12-03',
      amount:'1 Crore',
      description:"I must say that it's the best Ludo  application because it has a lowest platform fee, Less commission and you can do instant withdrawal to the money you have earned."
    },
    {
      name:'Manjeet',
      img:rev2,
      game:'Ludo Winner',
      date:'2024-12-03',
      amount:'50 Lakh',
      description:'I have been playing in LPL for 1 years before this I have played game on many platforms but I have got the beat experience here.'
    },
    {
      name:'Aravinda',
      img:rev3,
      game:'Ludo Mega Winner',
      date:'2024-12-03',
      amount:'1.5 Lakh',
      description:"It's interesting and play games along.its fun and magic.I really enjoyed it brought goosebumps."
    },
    {
      name:'Swati',
      img:rev4,
      game:'Ludo Winner',
      date:'2024-12-03',
      amount:'13 Lakhs',
      description:'Enjoy unitterputed gameplay and navigate through the app seamlessly on ludo premier league.      '
    }
  ]

  const game_steps = [
    {
      heading:'Login to your Game',
      description:'Use the login method access to play the game.',
      image:htp1
    },
    {
      heading:'Select The Game',
      description:'Choose the Live or Upcoming Match to join a game as participant.',
      image:htp2
    },
    {
      heading:'Play the Game',
      description:'Play the game and think of each move which result to win.',
      image:htp3
    },
    {
      heading:'Win the Game',
      description:'Check the rewars based on the game result.',
      image:htp4
    },
  ]

  return (
    <div className='h-[100%]'>

        <div className='hidden md:block relative z-10'>
          <Carousel 
            centerMode={true} 
            autoPlay={true} 
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            swipeable={true}
            autoFocus={true}
            interval={5000}
            centerSlidePercentage={100}
            showIndicators={false}
            className='relative bg-red-100 overflow-hidden -z-0'
          
            onChange={(e)=>{
              setindex(e)
            }}
            
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-[35px] z-100 border-r-2 border-gray-100 bottom-0 bg-[#760c0a]  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowBack size={20} className="text-white" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-0 bottom-0 bg-[#760c0a]  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowForward size={20} className="text-white" />
                </div>
              );
            }}
          
          >
              {images?.map((c,i)=>(
                  <div className='text-center overflow-hidden w-[100%] h-[94vh] md:h-[94vh]  overflow-auto z-1  mx-auto focus:outline-none focus:ring-0'>
                      <img src={c}  className='w-full  relative h-[94vh] md:h-[94vh] object-stretch md:object-stretch focus:outline-none focus:ring-0 '/>
                  </div>    
              ))}

                     


             

          </Carousel>

                
         
        </div>

        <div className='block md:hidden relative z-10'>
          <Carousel 
            centerMode={true} 
            autoPlay={true} 
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            swipeable={true}
            autoFocus={true}
            interval={5000}
            centerSlidePercentage={100}
            showIndicators={false}
            className='relative bg-red-100 overflow-hidden -z-0'
          
            onChange={(e)=>{
              setindex(e)
            }}
            
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-[35px] z-100 border-r-2 border-gray-100 bottom-0 bg-[#760c0a]  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowBack size={20} className="text-white" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-0 bottom-0 bg-[#760c0a]  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowForward size={20} className="text-white" />
                </div>
              );
            }}
          
          >
              {images_mobile?.map((c,i)=>(
                  <div className='text-center overflow-hidden w-[100%] h-[77vh] md:h-[77vh]  overflow-auto z-1  mx-auto focus:outline-none focus:ring-0'>
                      <img src={c}  className='w-full  relative h-[77vh] md:h-[77vh] object-stretch md:object-stretch focus:outline-none focus:ring-0 '/>
                  </div>    
              ))}

                     


             

          </Carousel>

                
         
        </div>


      

       <div className='block md:hidden shadow-2xl fixed ml-[2.5%] w-[95%] mx-auto bottom-[5px] rounded-[10px] z-20 my-[20px] md:w-[380px] mx-[5%]  mx-auto flex items-center justify-around bg-white p-[4px] py-[10px] my-[5px] elevation'>

            <div onClick={()=>openUrl(1)} className='shadow-lg cursor-pointer flex w-[48%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-gradient-to-r  from-[#29d25d] via-[#25ba44] to-[#1e9a22]'> 
                <IoLogoAndroid color='#fff' size={35} />
                <div className='ml-2'>
                    <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                    <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>Play Store</h6>
                </div>

            </div>

            <div onClick={()=>openUrl(2)} className='shadow-lg cursor-pointer flex w-[48%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-gradient-to-r  from-[#ef453f] via-[#d42f2e] to-[#b41218] '> 
                <FaApple color='#fff' size={35} />
                <div className='ml-2'>
                    <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                    <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>APP Store</h6>
                </div>

            </div>

        </div>

        {/* <div className='py-[5%]'>
        <h6 data-aos="fade-left" className='font_bold text-center'>About Us</h6>
        <h6  data-aos="fade-left"className='text-[13px] text-center mb-2 font_thick break-word mt-2'>At LPL we offer you Ludo game with various leagues to stay entertained and earn at the same time. We give you an opportunity to use your Ludo knowledge and skill to convert your love for the game into cool cash awards. With our customer-centric approach, we ensure the highest possible gain for our players in most reliable and secure way.</h6>
        <h6 data-aos="fade-up" className='pl-[2%] text-red-500 font_bold text-[15px]'>@ Ludo Premier Leauge</h6>
        
        
        <h6 data-aos="fade-left" className='font_bold text-center mt-10'>Truly Best <span className='text-red-600 text-[20px]'>Gaming Platform</span> <br></br> </h6>
        <h6 data-aos="fade-right" className='font_normal mt-2 text-[12px] text-center'>Trusted by more than <span className='font_bold text-red-500'> 1 Million </span> people a handy ludo game to earn money!</h6>

        <div className='md:w-[50%] mx-auto md:flex mt-5 item-center justify-between'>

          <div data-aos="fade-up" className='md:w-[48%] bg-gradient-to-r mb-8 relative w-[90%] mx-auto  h-[100px] from-[#eeeeee] via-[#ffffff] to-[#eeeeee] border-2 shadow-lg border-gray-300 rounded-[12px] px-[14px] py-[10px] mb-2'>
            <div  className='flex items-center'>
                <AiOutlineSafetyCertificate className='text-red-500' />
                <h6 className='font_bold text-[13px] ml-2'>World class security</h6>
            </div>
            <ul className='ml-2 list-disc mt-1'>
              <li className='text-[12px] font_thick'>RNG certified gaming platform</li>
              <li className='text-[12px] font_thick'>100% safe and secure transaction</li>
              <li className='text-[12px] font_thick'>Complete data and identity privacy</li>
            </ul>

          </div>

          <div data-aos="fade-up" className='md:w-[48%] bg-gradient-to-r mb-8 relative w-[90%] mx-auto  h-[100px] from-[#eeeeee] via-[#ffffff] to-[#eeeeee] border-2 shadow-lg border-gray-300 rounded-[12px] px-[14px] py-[10px] mb-2'>
            <div className='flex items-center'>
                <BiMoneyWithdraw className='text-red-500' />
                <h6 className='font_bold text-[13px] ml-2'>Innovative gaming platform</h6>
            </div>
            <ul className='ml-2 list-disc mt-1'>
              <li className='text-[12px] font_thick'>Instant withdrawals & winnings.</li>
              <li className='text-[12px] font_thick'>Play multiple leagues.</li>
            </ul>

          </div>

          </div>

        </div> */}

        
        <div className='py-[2%]'>
        <h6 data-aos="fade-left" className='font_bold text-center'>How To Play <span className='text-red-600 text-[20px]'>Ludo Premier Leaugue</span> <br></br><span className='text-[13px] text-gray-500'>and Win Exiting Rewards buy participating in game</span> </h6>
         

         <h6 data-aos="fade-right" className='font_bold text-center mt-5'>It's easy to start playing on <span className='text-red-600 text-[15px]'>LPC</span></h6>

         <h6 className='text-[#2072E4] text-center font_bold text-[11px] my-2 bg-gradient-to-r  from-[#FFFFFF] via-[#E3EFFF] to-[#FFFFFF ] md:w-[30%] mx-auto py-2'>Play Ludo games on LPL and win big prizes !</h6>
         <h6 data-aos="fade-up" className='w-[95%] md:w-[80%] mx-auto text-center font_thick text-[13px] my-5'>Enter into the thrilling world of Fantasy games, a strategy-based online sports game where you can create a game and invite your friends for the matches. and you can also participate with the other people's in a live game and earn exciting rewards. </h6>

         
         <div className='mt-16 w-[90%] md:w-[80%] mx-auto grid gap-4 md:grid-cols-4'>
         {game_steps?.map((g,i)=>(

          <div data-aos="fade-up" className='bg-gradient-to-r mb-8 relative w-[90%] mx-auto  h-[100px] from-[#eeeeee] via-[#ffffff] to-[#eeeeee] border-2 shadow-lg border-gray-300 rounded-[12px] px-[14px] py-[10px] mb-2'>
            <img src={g?.image} alt="img" className='h-[70px] w-[120px] absolute right-2 -top-10 rounded-[10px] shadow-2xl border' />
                <h6 className='bg-[#10a63c] font_bold text-[16px] text-white border-2 border-white w-[35px] -top-[18px] shadow-md absolute h-[35px] flex items-center justify-center rounded-full text-center'>{i+1}</h6>
                <h6 className='font_bold mt-5 text-[14px]'>{g?.heading}</h6>
                <h6 className='text-[12.5px]'>{g?.description}</h6>
          </div>  
         ))}
         </div>
        </div>

     
        <div className='py-[2%]'>

   
          <h6 data-aos="fade-left" className='font_bold text-center'>Install <span className='text-red-600 text-[20px]'>Ludo Premier Leaugue</span> <br></br><span className='text-[13px] text-gray-500'>and Win Exiting Rewards , Amount</span> </h6>

          <div className='md:flex md:w-[60%] my-10 mx-auto'>
          <div data-aos="fade-left" className='w-[100%] md:w-[50%]'>
          <img src={mobileAppLogo1} className='w-[100%]  px-[5%] object-cover  mx-auto' />
          </div> 
          <div data-aos="fade-right" className='mx-5 -mt-5 md:mt-0'>
            <div className='md:ml-10 flex items-center  mt-12'>
              <PiGameController size={30} className='bg-slate-100 w-[38px] p-[7px] rounded-full' />
              <h1 className='font_thick text-[13.5px] ml-4'>Play Each game with different users or <span className='text-blue-600'>create your own private contest</span>.</h1>
            </div>
            <div className='md:ml-10 flex items-center  mt-4'>
              <LuUserPlus2 size={30} className='bg-slate-100 w-[30px] p-[7px] rounded-full' />
              <h1 className='font_thick text-[13.5px] ml-4'>Invite your friends & collect big rewards.</h1>
            </div>
            <div className='md:ml-10 flex items-center mt-4'>
              <PiMedalLight size={30} className='bg-slate-100 w-[30px] p-[7px] rounded-full' />
              <h1 className='font_thick text-[13.5px] ml-4'>Play more, earn DreamCoins & get exciting rewards.</h1>
            </div>

            <div className='my-[20px] mt-5 w-[100%] md:w-[380px] mx-auto flex items-center justify-around bg-white p-[4px] py-[10px] rounded-[8px] my-[5px] elevation'>

                  <div onClick={()=>openUrl(1)} className='shadow-lg cursor-pointer flex w-[47%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-[#010101]'> 
                      <IoLogoAndroid color='#79c05d' size={32} />
                      <div className='ml-2'>
                          <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                          <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>Play Store</h6>
                      </div>

                  </div>

                  <div onClick={()=>openUrl(2)} className='shadow-lg cursor-pointer flex w-[47%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-[#010101] '> 
                      <FaApple color='#fff' size={32} />
                      <div className='ml-2'>
                          <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                          <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>APP Store</h6>
                      </div>

                  </div>

            </div>
          </div>
          </div> 
        </div>

     
        <div className='bg-gray-100 py-[2%]'>

        <h6 data-aos="fade-left" className='text-center font_bold mb-2 text-[16px] pt-5'>What Our User Say About <span className='text-red-700'>LPL</span></h6>
        <h6 data-aos="fade-right" className='text-center font_normal text-[13px]'>Reviews & Rating</h6>



        <div className='mt-10 grid md:grid-cols-4 md:w-[85%] mx-auto'>
            {reviews?.map((r)=>(
                <div data-aos="fade-up" className='border bg-white mb-10 mx-2 rounded-[10px] shadow-lg'>
                  <div className='flex items-center px-5 py-2'>
                      
                      <img src={r?.img} className='w-[50px] h-[50px] object-cover border-[3px] border-[#d9d9d9] rounded-full -mt-8' />
                      <h6 className='ml-5 font-[800] text-[14px] font_bold mt-1'>{r?.name}</h6>
                  </div> 
                  <div className='bg-[#1a1a1a] px-2 py-[5px] justify-between text-white flex'>
                    <div>
                        <h6 className='font_bold text-[13px]'>{r?.game}</h6>
                        <h6 className='text-[11px] font_normal opacity-70'>{r?.date}</h6>
                    </div> 

                    <div>
                        <h6 className='font_bold text-[12px] text-[#ffd153]'>₹ {r?.amount}</h6>
                        <h6 className='font_normal text-[9px] opacity-70'>WINNINGS</h6>
                    </div> 
                  </div>
                  <h6 className='text-[12px] font_normal px-2 py-2'>{r?.description}</h6> 
                </div>  
            ))}
        </div> 


        <div className='bg-white mx-5 w-[90%] md:w-[400px] md:flex mx-auto rounded-[10px] px-5 py-2 mt-5 mb-5 md:mt-10 shadow-lg border border-gray-100 overflow-hidden'>
          
          <div className='flex items-center justify-center py-2'>
            <h6 className='font_normal text-[14px]'>App Rating</h6>
            <div className='flex ml-3'>
                <IoMdStar size={20} color='#fccc37' />
                <IoMdStar size={20} color='#fccc37' />
                <IoMdStar size={20} color='#fccc37' />
                <IoMdStar size={20} color='#fccc37' />
                <IoMdStarHalf size={20} color='#fccc37' />
            </div>
          </div>
          <div className='flex md:ml-2 items-center justify-center py-1'>
              <h6 className='font_normal text-[13px] mr-2'>4.7</h6>
              <h6 className='text-gray-400 text-[13px] border-l-2 pl-2'>2,750,143 ratings</h6>
          </div>
        </div>

        </div>

        <div className='my-10'>
          <h6 data-aos="fade-left" className='text-center font_bold mb-2 text-[16px]'>FAQs</h6>
          <h6 data-aos="fade-right" className='text-center font_normal mb-5 text-[14px]'>What our ask us about our app</h6>
         
          {faq?.map((f)=>(
            <div data-aos="fade-up" onClick={()=> f?.id === selectedFaq ? setselectedFaq('') : setselectedFaq(f?.id)} className='border border-gray-300 w-[80%] md:w-[50%] mb-4 mx-auto rounded-[10px] overflow-hidden'>
              <div className='flex items-center justify-between bg-gray-100'>
                <h6 className='text-[14px] p-[10px] font_normal'>{f?.label}</h6>
              <MdKeyboardArrowDown size={25} className='mr-2' />
              </div>
              {selectedFaq === f?.id && <h6 className='transition-all duration-300 text-[13px] p-[10px]'>{f?.value}</h6>}
            </div>  
          ))}
        </div>
    </div>
  )
}

export default Home
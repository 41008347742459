import React from 'react'
import { appLogo } from '../constants/AppConstant'

function Splash() {
  return (
    <div className='bg-gradient-to-r flex flex-col items-center justify-center h-screen from-[#760c0a] via-[#430a08] to-[#380908] md:from-[#170212] md:via-[#2e0712] md:to-[#070112] '>
         <img data-aos="zoom-in-up" 
             data-aos-easing="ease-in-back"
             data-aos-delay="500"
             data-aos-offset="0" src={appLogo} className='w-[100%] h-[100px] object-contain' />
           <h6 className='font_bold text-white text-center text-[13px] mt-5'>Enter the Ludo Gaming World</h6>
    </div>
  )
}

export default Splash
import React from 'react'
import { gamePlayLogo } from '../constants/AppConstant'
import { htp1, htp2, htp3, htp4, mobileAppLogo1 } from '../constants/AppConstant';

function HowToPlay() {

  const game_steps = [
    {
      heading:'Login to your Game',
      description:'Use the login method access to play the game.',
      image:htp1
    },
    {
      heading:'Select The Game',
      description:'Choose the Live or Upcoming Match to join a game as participant.',
      image:htp2
    },
    {
      heading:'Play the Game',
      description:'Play the game and think of each move which result to win.',
      image:htp3
    },
    {
      heading:'Win the Game',
      description:'Check the rewars based on the game result.',
      image:htp4
    },
  ]

  return (
    <div className='w-screen overflow-x-hidden'>
      

       <div className='relative flex  items-center justify-center  md:h-[250px] bg-contain bg-right bg-no-repeat bg-bottom  px-4 py-[10px] bg-gradient-to-r  from-[#00061a] via-[#00061a] to-[#00061a] md:from-[#00061a] md:via-[#00061a] md:to-[#00061a]'>


        <div className='absolute bg-gradient-to-r  bg-how_play  bg-right bg-no-repeat  bg-contain opacity-100 w-full h-[250px]'>
        </div>   
          

        <div className='px-[2%] flex flex-col items-center justify-center text-white py-[2%] z-20 md:py-[5%]'>
            <h1 className='text-[28px] font_thicksss pt-5 md:pt-0 mb-2'>How To Play</h1>
            <h6 className=' font_thick text-[13px] opacity-80 mb-2 text-center md:w-[70%]'>You have any doubts to play our game just follow us the below steps to enjoay the game without any difficulties</h6>
            
           
        </div>

       
        </div>


      <div className='py-[20%] md:py-[5%] px-[5%]'>


      <h6 data-aos="fade-left" className='text-[#2072E4] text-center font_bold text-[11px] my-2 bg-gradient-to-r  from-[#FFFFFF] via-[#E3EFFF] to-[#FFFFFF ] md:w-[30%] mx-auto py-2'>How to Play Ludo Premier Leauge ???</h6>
      <h6 data-aos="fade-right" className='w-[95%] md:w-[80%] mx-auto text-center font_bold text-[13px] my-5'> Ludo Premier Leauge is a ludo gaming platform where you compete with the other to earn money and other exciting prizes.</h6>
         
         <h6 data-aos="fade-up" className='w-[95%] md:w-[80%] mx-auto text-center font_thick text-[13px] my-5'>Enter into the thrilling world of Fantasy games, a strategy-based online sports game where you can create a game and invite your friends for the matches. and you can also participate with the other people's in a live game and earn exciting rewards. </h6>


      <div className=''>

        <img data-aos="fade-up" src={gamePlayLogo} className=' shadow-lg mx-auto mt-2 md:mt-10 border border-gray-200 rounded-[10px]' />

        <div className='py-[0%] mt-10'>
        <h6 data-aos="fade-left" className='font_bold text-center'>How To Play <span className='text-red-600 text-[20px]'>Ludo Premier Leaugue</span> <br></br><span className='text-[13px] text-gray-500'>and Win Exiting Rewards buy participating in game</span> </h6>
         

         <h6 data-aos="fade-right" className='font_bold text-center mt-5'>It's easy to start playing on <span className='text-red-600 text-[15px]'>LPC</span></h6>

         <h6 className='text-[#2072E4] text-center font_bold text-[11px] my-2 bg-gradient-to-r  from-[#FFFFFF] via-[#E3EFFF] to-[#FFFFFF ] md:w-[30%] mx-auto py-2'>Play Ludo games on LPL and win big prizes !</h6>
         <h6 className='w-[95%] md:w-[80%] mx-auto text-center font_thick text-[13px] my-5'>Enter into the thrilling world of Fantasy games, a strategy-based online sports game where you can create a game and invite your friends for the matches. and you can also participate with the other people's in a live game and earn exciting rewards. </h6>

         
         <div className='mt-16 w-[90%] md:w-[90%] mx-auto grid gap-4 md:grid-cols-4'>
         {game_steps?.map((g,i)=>(

          <div data-aos="fade-up" className='bg-gradient-to-r mb-8 relative w-[90%] mx-auto  h-[100px] from-[#eeeeee] via-[#ffffff] to-[#eeeeee] border-2 shadow-lg border-gray-300 rounded-[12px] px-[14px] py-[10px] mb-2'>
            <img src={g?.image} alt="img" className='h-[70px] w-[120px] absolute right-2 -top-10 rounded-[10px] shadow-2xl border' />
                <h6 className='bg-[#10a63c] font_bold text-[16px] text-white border-2 border-white w-[35px] -top-[18px] shadow-md absolute h-[35px] flex items-center justify-center rounded-full text-center'>{i+1}</h6>
                <h6 className='font_bold mt-5 text-[14px]'>{g?.heading}</h6>
                <h6 className='text-[12.5px]'>{g?.description}</h6>
          </div>  
         ))}
         </div>
        </div>

      </div>
      </div>
    </div>
  )
}

export default HowToPlay
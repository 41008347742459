import React from 'react'
import { IoLogoAndroid } from "react-icons/io";
import { FaApple } from "react-icons/fa";
import { mobileappLogo, openUrl } from '../constants/AppConstant';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function DownloadApp() {
  return (
    <div>
        <div className='relative min-h-[200px] h-[250px] flex items-center justify-center bg-gradient-to-r h-full opacity-100 absolute w-full top-0 left-0  from-[#760c0a] via-[#430a08] to-[#380908] md:from-[#170212] md:via-[#2e0712] md:to-[#070112]'>
        <div className='absolute right-0 z-0 bg-mobile_app_bg w-[200px] md:w-full opacity-30 md:opacity-70 h-full bottom-0 rotate-0 md:h-[250px] bg-cover md:bg-contain bg-no-repeat bg-right  px-4 py-[10px] '>

        </div>
            <div className='px-[2%] z-10 flex flex-col items-center justify-center text-white py-[2%] z-0 md:py-[5%]'>
                <h1 className='text-[28px] font_thicksss mb-2'>Download Our LPL</h1>
                <h6 className=' font_thick text-[13px] opacity-80 mb-2 text-center md:w-[70%]'>Easy Install , Easy Play , Easy Winning Game ,For any issues give us a call </h6>
            </div>

            
            </div>
        
        <div className='px-[5%] w-screen h-auto  md:flex items-center py-[15%] md:py-[5%]'>


            <div className='md:w-[50%] '>

                <img data-aos="fade-up"  src={mobileappLogo} className='w-full object-contain h-[400px] md:h-[550px]' />

            </div>
            <div className='mt-10 md:mt-0 md:w-[50%]'>
                <h6 data-aos="fade-left" className='font_bold text-[30px]'>Download Our Mobile App</h6>
                <h6 data-aos="fade-up" className='mb-2 font_normal text-[14px]'>Welcome to Exciting Ludo Battles – Download Our App Today!</h6>
                <h6 data-aos="fade-up" className='mb-1 font_normal text-[14px]'>Get ready for an unmatched betting experience with the Ludo Premier League Betting App. Join thousands of players who are already enjoying the thrill of the game and winning big!</h6>

                <h6 data-aos="fade-up" className='mb-[10px] font-[900] font_bold text-[14px]'>Why download our app?</h6>
                <ul data-aos="fade-up" className='ml-10 my-[10px]'>
                    <li className='mb-1 font_normal text-[14px]'>Secure and Fair Play: Ensured by our state-of-the-art technology.</li>
                    <li className='mb-1 font_normal text-[14px]'>Engage in Real-Time: Play against friends or rivals from all over the world.</li>
                    <li className='mb-1 font_normal text-[14px]'>Exclusive Bonuses: Start strong with a bonus just for signing up!</li>
                </ul>

                <h6 data-aos="fade-up" className='mb-2 font_normal text-[14px]'>Getting started is easy! Just click on the link below to download the app on your device:</h6>
                <h6 data-aos="fade-up" className='mb-2 font_normal text-[14px]'>Don't miss out on the excitement! Download today and claim your place among the champions of Ludo Premier League.</h6>


                <div  data-aos="zoom-in-up" data-aos-delay="300" className='my-[20px] mx-auto md:w-[360px] -ml-5 flex items-center justify-around bg-white p-[4px] py-[10px] rounded-[8px] my-[5px] elevation'>

                <div onClick={()=>openUrl(1)} className='shadow-lg cursor-pointer flex w-[47%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-gradient-to-r  from-[#29d25d] via-[#25ba44] to-[#1e9a22]'> 
                    <IoLogoAndroid color='#fff' size={35} />
                    <div className='ml-2'>
                        <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                        <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>Play Store</h6>
                    </div>

                </div>

                <div onClick={()=>openUrl(2)} className='shadow-lg cursor-pointer flex w-[47%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-gradient-to-r  from-[#ef453f] via-[#d42f2e] to-[#b41218] '> 
                    <FaApple color='#fff' size={35} />
                    <div className='ml-2'>
                        <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                        <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>APP Store</h6>
                    </div>

                </div>

            </div>
            </div>




        </div>
    </div> 
  )
}

export default DownloadApp
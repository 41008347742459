import React from 'react'

function PointSystem() {
  return (
    <div className='w-screen overflow-x-hidden'>
         <div className='relative flex  items-center justify-center   md:h-[250px]  px-4 py-[10px] bg-gradient-to-r  from-[#00061a] via-[#00061a] to-[#00061a] md:from-[#00061a] md:via-[#00061a] md:to-[#00061a]'>


            <div className='absolute  bg-game_point_bg bg-contain bg-no-repeat h-full  w-full  md:w-full md:h-[250px]'>
            </div>   
            

            <div className='px-[2%] flex flex-col items-center justify-center text-white py-[2%] z-20 md:py-[5%]'>
                <h1 className='text-[28px] font_thicksss pt-5 md:pt-0 mb-2'>Point System</h1>
                <h6 className=' font_thick text-[13px] opacity-80 mb-2 text-center md:w-[70%]'>You have any doubts how pointing system works in our ludo games see the below bifurcation.</h6>
            </div>


            </div>

        <div className='px-[10%] py-[5%]'>
             <h6 className='font_bold underline text-[17px]'>Point System</h6>

             <ul className='mt-4 ml-4 list-decimal'>
                <li className='mb-1.5 font_normal text-[13px]'>Each Dice movement = +1 point</li>
                <li className='mb-1.5 font_normal text-[13px]'>For hitting the token = +50 points</li>
                <li className='mb-1.5 font_normal text-[13px]'>Get attacked by opponent = -50 points</li>
                <li className='mb-1.5 font_normal text-[13px]'>Reaching home 1st token = +100points</li>
                <h6 className='mb-1.5 font_normal text-[13px]'>2nd token = +150 point</h6>
                <h6 className='mb-1.5 font_normal text-[13px]'>3rd token = +200 point</h6>
                <h6 className='mb-1.5 font_normal text-[13px]'>4th token = +250 point</h6>
                <li className='mb-1.5 font_normal text-[13px]'>For not using magic dice and save token 50 points each</li>
                <li className='mb-1.5 font_normal text-[13px]'>For crossing each safe zone = +10 point</li>
             </ul>
        </div>
    </div>
  )
}

export default PointSystem
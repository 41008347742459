import React, { useState } from 'react'
import { appLogo, openCall } from '../constants/AppConstant'
import { CgMenuRight } from "react-icons/cg";
import { GrFormClose } from "react-icons/gr";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Drawer } from 'antd'
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
function Navbar() {
 
  const navigator = useNavigate()
  const [modal,setmodal] = useState(false)

  
  const menu = [
    {icon:'',name:'Home',path:'/'},
    {icon:'',name:'About',path:'/about_us'},
    {icon:'',name:'How To Play',path:'/how_to_play'},
    {icon:'',name:'Point System',path:'/point_system'},
    {icon:'',name:'Contact Us',path:'/contact_us'},
    {icon:'',name:'Download App',path:'/mobile_app'}
  ]
  async function openPage(v){
    setmodal(false)
    navigator(v)
  }

  return (
    <div className='relative'>
        <div className={`flex items-center  justify-between bg-gradient-to-r  from-[#760c0a] via-[#430a08] to-[#380908] md:from-[#170212] md:via-[#2e0712] md:to-[#070112] px-4 py-[10px]`}>
            <img src={appLogo} key={'logo'} className='w-[150px] h-[38px] -ml-5 md:ml-0 mt-[-4px] object-contain' />
            
            <div className='hidden md:flex items-center text-white'>
            {menu?.map((m)=>(
              <h6 onClick={()=>openPage(m?.path)} className='mx-2 cursor-pointer opacity-60 hover:opacity-100 font_bold text-[13px]'>{m?.name}</h6>
            ))}  

            <div>
               <h6 onClick={openCall} className='mx-2 cursor-pointer font_bold text-[13px] hover:bg-[#24d366] duation-300 hover:text-black hover:border-[#24d366] border p-[4px] px-[10px] rounded-[4px]'>Contact Us</h6>
            </div>
            </div>

            <div onClick={()=>setmodal(!modal)} className='block md:hidden bg-[#760c0a] flex items-center justify-center cursor-pointer p-[4px] rounded-full w-[30px]'>
              {!modal ? <CgMenuRight size={20} color='#fff' className='opacity-100' /> : <GrFormClose size={20} color='#fff'  />}
            </div>
            <Drawer open={modal} placement='top' closable={false} height={325} bodyStyle={{ padding:'0px' }}>
             <div className={`w-[100%] bg-gradient-to-r  from-[#760c0a] via-[#430a08] to-[#380908]`}>
            
            <div className='flex items-center py-5 justify-between mr-5'>
            <img src={appLogo} key={'logo'} className='w-[150px] h-[38px] mt-[-4px] object-contain' />
            <AiOutlineClose size={20} onClick={()=>setmodal(!modal)} className='bg-white p-[2px] rounded ' />
            </div>
              {menu?.map((m,i)=>(
                  <div onClick={()=>openPage(m?.path)} className={`px-[20px] group pointer relative py-[15px] ${i == 0 && 'border-t'} border-b border-red-900`}>
                    <h6 className='w-[6px] h-[35px] hidden group-hover:absolute bg-red-400 left-0 top-[12%] rounded-tr-lg rounded-br-lg'></h6>
                    <h6 className='text-gray-300 font-[700] text-[13px]'>{m?.name}</h6>
                    <IoIosArrowRoundForward  className='hidden  text-white group-hover:absolute right-4 top-[40%]' />
                  </div>     
              ))}
            </div>
            </Drawer>

           
        </div>


        {/* <div className='shadow-2xl my-[20px] w-[90%] md:w-[380px] mx-auto flex items-center justify-around bg-white p-[4px] py-[10px] rounded-[8px] my-[5px] elevation'>

            <div className='shadow-lg cursor-pointer flex w-[47%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-gradient-to-r  from-[#29d25d] via-[#25ba44] to-[#1e9a22]'> 
                <IoLogoAndroid color='#fff' size={35} />
                <div className='ml-2'>
                    <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                    <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>Play Store</h6>
                </div>

            </div>

            <div className='shadow-lg cursor-pointer flex w-[47%] items-center px-[15px] py-[8px] rounded-[10px] overflow-hidden w-max bg-gradient-to-r  from-[#ef453f] via-[#d42f2e] to-[#b41218] '> 
                <FaApple color='#fff' size={35} />
                <div className='ml-2'>
                    <h6 className='text-[#fff] text-[10px]'>Download for</h6>
                    <h6 className='text-[#fff] font-[900] uppercase text-[13px] -mt-[2px]'>APP Store</h6>
                </div>

            </div>

        </div> */}
    </div>
  )
}

export default Navbar
import React from 'react'

function CommunityGuideLines() {
  return (
    <div className='px-[5%] py-[15%] md:py-[5%]'>

        <h6 className='font-[900] text-[16px] font_bold'>Community Guidelines</h6>
        <h6 className='font_normal text-gray-500 text-[13px]'><span className='text-blue-500 hover:underline'>Home</span>  / <span>Community Guidelines</span></h6>

        <h6 className='my-4 font-[900] font_bold text-[14px]'>Last Update : April 12,2024</h6>

        <div>

            <h6 className='font-[800] text-[14px] font_bold'>Introduction</h6>
            <h6 className='text-[14px] font_normal mb-4'>Welcome to the Ludo Premier League Betting App! To ensure the best experience for all of our users, we ask that you follow these community guidelines. These rules are designed to foster a friendly and welcoming environment, ensuring safe and enjoyable gameplay for everyone.</h6>

          
            <h6 className='font-[800] text-[14px] font_bold'>Respect for All Users</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'>Treat all players with respect and courtesy.</li>
             <li className='text-[14px] font_normal mb-1.5'>Engage with others in a positive and respectful manner.</li>
             <li className='text-[14px] font_normal mb-1.5'>Do not engage in harassment, bullying, threats, or any form of intimidation.</li>
             <li className='text-[14px] font_normal mb-1.5'>Discriminatory language, hate speech, or other derogatory or offensive behavior based on race, gender, religion, nationality, disability, sexual orientation, or age is strictly prohibited.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Fair Play</h6>
            <ul className='ml-8 list-disc py-4'>
             <li className='text-[14px] font_normal mb-1.5'>Play fairly and follow the rules of the game.</li>
             <li className='text-[14px] font_normal mb-1.5'>Do not use cheats, exploits, automation software, bots, hacks, mods or any unauthorized third-party software designed to modify or interfere with the Ludo Premier League Betting App experience.</li>
             <li className='text-[14px] font_normal mb-1.5'>Do not attempt to manipulate scores in any way that gives you or others an unfair advantage.</li>
            </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Responsible Communication</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> Keep your language clean and appropriate for a diverse audience.</li>
            <li className='text-[14px] font_normal mb-1.5'> Avoid sharing or promoting false information.</li>
            <li className='text-[14px] font_normal mb-1.5'> Do not spam, post repetitive messages, or flood the chat with excessive posts.</li>
           </ul>
            <h6 className='font-[800] text-[14px] font_bold'>Protecting Privacy</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> Respect the privacy of others. Do not share or solicit personal information about other users or any third parties.</li>
            <li className='text-[14px] font_normal mb-1.5'> Do not post or distribute private or confidential information without explicit permission.</li>
           </ul>
           <h6 className='font-[800] text-[14px] font_bold'>Content Guidelines</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> Do not upload, post, or share content that is abusive, inflammatory, sexually explicit, or obscene.</li>
            <li className='text-[14px] font_normal mb-1.5'> Respect copyright and other intellectual property rights. Only share content if you have the right to do so.</li>
           </ul>
           <h6 className='font-[800] text-[14px] font_bold'> Promotions and Advertising</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> Do not use the app for unauthorized commercial activities, advertisements, or solicitations.</li>
            <li className='text-[14px] font_normal mb-1.5'> Promotions of other products, services, or events not related to Ludo Premier League are prohibited unless officially sanctioned by the Ludo Premier League Betting App administration.</li>
           </ul>
           <h6 className='font-[800] text-[14px] font_bold'>Reporting and Enforcement</h6>
            <ul className='ml-8 list-disc py-4'>
            <li className='text-[14px] font_normal mb-1.5'> If you encounter someone violating these community guidelines, please report them using the in-app reporting tools.</li>
            <li className='text-[14px] font_normal mb-1.5'> We reserve the right to remove any content that violates these guidelines and to suspend or terminate accounts for severe or repeat offenses.</li>
           </ul>
           <h6 className='font-[800] text-[14px] font_bold'>Changes to the Guidelines</h6>
            <h6 className='text-[14px] font_normal mb-4'> We may update these community guidelines from time to time to ensure they continue to provide a safe and respectful environment. Continued use of the app after such changes constitutes your acceptance of the new guidelines.</h6>
          
           <h6 className='font-[800] text-[14px] font_bold'>Conclusion</h6>
            <h6 className='text-[14px] font_normal mb-4'> We thank you for adhering to these guidelines and helping us maintain a safe and friendly gaming environment. Your cooperation ensures that the Ludo Premier League Betting App remains a fun and welcoming place for all players.</h6>
    
        
            <h6 className='text-[14px] font_normal mb-4'> These guidelines serve as a baseline for behavior within the app and ensure that the community remains constructive and enjoyable for everyone. Always consider local laws and cultural norms, which may also affect how you should formulate and enforce these guidelines.</h6>

        </div>  
        
    </div>
  )
}

export default CommunityGuideLines